const json = [
    {
        "gamename": "Black Desert",
        "store": "Steam",
        "gamedescription": "Played by over 20 million Adventurers - Black Desert Online is an open-world, action MMORPG. Experience intense, action-packed combat, battle massive world bosses, fight alongside friends to siege and conquer castles, and train in professions such as fishing, trading, crafting, cooking, and more!",
        "gamelink": "https://store.steampowered.com/app/582660/Black_Desert/",
        "gamecoverurl": "https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/582660/header.jpg?t=1734657703",
        "freeuntil": "6/1-2025"
    },
    {
        "gamename": "Wizard of Legend",
        "store": "Epic Games Store",
        "gamedescription": "Vampire Survivors is a gothic horror casual game with rogue-lite elements, where your choices can allow you to quickly snowball against the hundreds of monsters that get thrown at you.",
        "gamelink": "https://store.epicgames.com/en-US/p/wizard-of-legend-21c07d",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/b1d735eb93de4c6da325aa9a6db9a5a5/wizard-of-legend-nuvjw.png?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "23/12-2024"
    },
    {
        "gamename": "Astrea: Six Sided Oracles",
        "store": "Epic Games Store",
        "gamedescription": "Astrea is a DICE-deck-building roguelike that flips the script on deckbuilders by using dice instead of cards and an unique dual “damage” system: Purification vs Corruption. Build a dice pool strong enough to purify Astrea's out-of-control corruption and save the Star System.",
        "gamelink": "https://store.epicgames.com/en-US/p/astrea-six-sided-oracles-33c949",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/4515173972ae4444a2582bc690c150bd/astrea-six-sided-oracles-offer-bvy0n.png?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "TerraTech",
        "store": "Epic Games Store",
        "gamedescription": "TerraTech is an open-world, sandbox adventure game, where you design and build your own creations through a mix of crafting, combat & discovery. Design vehicles from a huge library of blocks. Scavenge, craft & buy new parts to survive, becoming the ultimate planetary prospector.",
        "gamelink": "https://store.epicgames.com/en-US/p/terratech-02ab0a",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/6e13abedd200415e9622043791acf055/terratech-sfx2t.png?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Vampire Survivors",
        "store": "Epic Games Store",
        "gamedescription": "Vampire Survivors is a gothic horror casual game with rogue-lite elements, where your choices can allow you to quickly snowball against the hundreds of monsters that get thrown at you.",
        "gamelink": "https://store.epicgames.com/en-US/p/vampire-survivors-f699a1",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/6091b3f3943e4a7aa25fcac13de15cd1/vampire-survivors-1enng.jpg?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Distant Space 2",
        "store": "Steam",
        "gamedescription": "Distant Space 2 - is an indirect continuation of the first part, in which you will find more dynamic gameplay. Your task is to destroy enemy ships on each level to go to the next of 26 available. In addition to conventional ships, you will have to wait for the boss ships, the battle with which will not be easy.",
        "gamelink": "https://store.steampowered.com/app/729460/Distant_Space_2/",
        "gamecoverurl": "https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/729460/header.jpg?t=1734475964",
        "freeuntil": "24/12-2024"
    },
    {
        "gamename": "Chicken Assassin: Reloaded",
        "store": "GoG",
        "gamedescription": "Chicken Assassin is a fast paced action-RPG with an outrageous storyline filled with over-the-top humor that boasts a myriad of colorful enemies and visually stunning levels and environment.",
        "gamelink": "https://www.gog.com/en/game/chicken_assassin_reloaded",
        "gamecoverurl": "https://images.gog-statics.com/f0f17f0b27325598dd79642fcbd95abb911972658c331b83ba96dba01d7bf1ef_product_card_v2_logo_480x285.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Lord of The Rings - Return to Moria",
        "store": "Epic Games Store",
        "gamedescription": "The only survival crafting game set in the Fourth Age of Middle-earth™, the iconic fantasy world created by J.R.R. Tolkien.",
        "gamelink": "https://store.epicgames.com/en-US/p/the-lord-of-the-rings-return-to-moria-f01344",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/2ae760629a384d5199cbefc612db7ac8/the-lord-of-the-rings-return-to-moria-rxsxh.jpg?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Pankapu",
        "store": "Steam",
        "gamedescription": "Pankapu is a narrative action-platformer that takes place in the dreams of Djaha’rell, a child troubled by a tragic incident. Upgrade your skills, find new competencies, switch your Aegis in real time in order to get rid of Nightmare's invasion.",
        "gamelink": "https://store.steampowered.com/app/418670/Pankapu/",
        "gamecoverurl": "https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/418670/header.jpg?t=1733164836",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Whispered World: Special Edition",
        "store": "GoG",
        "gamedescription": "When the fate of a world hangs in the balance, an unusual hero arises! The visually impressive adventure-epic The Whispered World is now available as a Special Edition for the first time. Let the Whispered World enchant you with its many animated details and fantastic characters. Sadwick's adventu...",
        "gamelink": "https://www.gog.com/en/game/the_whispered_world_special_edition",
        "gamecoverurl": "https://images.gog-statics.com/edb6a64af4bf97e540bfce7cca178b11a05b7f15572d369a577d54046e220f2c_product_card_v2_mobile_slider_639.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "LEGO Star Wars - The Skywalker Saga",
        "store": "Epic Games Store",
        "gamedescription": "Play through all nine Skywalker saga films in a game unlike any other. With over 300 playable characters, over 100 vehicles, and 23 planets to explore, a galaxy far, far away has never been more fun! *Includes classic Obi-Wan Kenobi playable character.",
        "gamelink": "https://store.epicgames.com/en-US/p/lego-star-wars-the-skywalker-saga",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/9c59efaabb6a48f19b3485d5d9416032/EGS_LEGOStarWarsTheSkywalkerSaga_TTGames_S1_2560x1440-ae89e9c91aec1e461148f93f25b828ed?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Bus Simulator 21 Next Stop",
        "store": "Epic Games Store",
        "gamedescription": "It's your stop! Bus Simulator 21 Next Stop is here! Enjoy many game improvements and a brand-new game mode with this comprehensive update. Career Mode combines Sandbox Mode with the campaign's economic system",
        "gamelink": "https://store.epicgames.com/en-US/p/bus-simulator-21",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/2b09519576cb4276bfd8df52f5288e4a/EGS_BusSimulator21NextStop_stillalivestudios_S1_2560x1440-ae24be3577061e1b8f850fffe2e5b3a8?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Brotato",
        "store": "Epic Games Store",
        "gamedescription": "Brotato is a top-down arena shooter roguelite where you play a potato wielding up to 6 weapons at a time to fight off hordes of aliens. Choose from a variety of traits and items to create unique builds and survive until help arrives.",
        "gamelink": "https://store.epicgames.com/en-US/p/brotato-ed4097",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/2c5525406ea34caebf391f1f4c8a44a5/brotato-logo-x3z3y.png?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Dark Sector",
        "store": "Steam",
        "gamedescription": "A third-person Action/Shooter that thrusts players into a sci-fi flavored nightmare scenario set in the post Cold War era.",
        "gamelink": "https://store.steampowered.com/app/29900/Dark_Sector/",
        "gamecoverurl": "https://shared.cloudflare.steamstatic.com/store_item_assets/steam/apps/29900/header.jpg?t=1732734420",
        "freeuntil": "expired"
    },
    {
        "gamename": "Beholder",
        "store": "Epic Games Store",
        "gamedescription": "You’re a state-installed landlord in a totalitarian country. Place listening devices, steal and sneak into your tenants’ apartments. Use what you uncovered to report anyone capable of plotting against the state. You MUST! But WILL you?",
        "gamelink": "https://store.epicgames.com/en-US/p/beholder-8c3b4c",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/e42eff0761f24bb089fe429611bd2e31/beholder-ud3jm.png?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Snakebird Complete",
        "store": "Epic Games Store",
        "gamedescription": "Introducing Snakebird Complete: The Ultimate Snakebird Experience! Embark on an extraordinary puzzle-solving adventure bringing together hit classic Snakebird and Snakebird Primer, offering the complete Snakebird experience in a single, comprehensive package.",
        "gamelink": "https://store.epicgames.com/en-US/p/snakebird-complete-e6f0ae",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/8ecb2895d94f4c64a743c1c4f056743f/snakebird-complete-m29wh.jpg?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Castlevania Anniversary Collection",
        "store": "Epic Games Store",
        "gamedescription": "Konami's Castlevania Anniversary Collection traces the origins of the historic vampire franchise. Included is a unique eBook with details provided by developers, artists and others inspired by the Castlevania legacy which sheds a fresh light into the world of Castlevania.",
        "gamelink": "https://store.epicgames.com/en-US/p/castlevania-anniversary-collection-a61f94",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/1ff2f74ba0ef4019b6905d8abe268819/castlevania-anniversary-collection-yex6f.jpg?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Deceive Inc.",
        "store": "Epic Games Store",
        "gamedescription": "Go undercover as the world’s greatest spies in this multiplayer game of subterfuge. Disguise as anyone, deploy high-tech gadgets or neutralize the competition. As long as you extract with the objective, no trick is too dirty when you work for DECEIVE INC.",
        "gamelink": "https://store.epicgames.com/en-US/p/deceive-inc",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/eca9a86adffa4605b8250d383fc01098/EGS_DeceiveInc_SweetBanditsStudios_S1_2560x1440-3a1e885fd642564a44875bf005d8ffb3?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Unloved",
        "store": "Steam",
        "gamedescription": "Based on the community award-winning Doom2 mod with the same name by the same creator, UNLOVED is reimagined in the Unreal Engine 4 to bring forth the unspeakable terror in new ways. Play coop with your friends, increase your skills, mod your weapons and face the horror that this unearthly place holds...",
        "gamelink": "https://store.steampowered.com/app/321270/UNLOVED/",
        "gamecoverurl": "https://shared.cloudflare.steamstatic.com/store_item_assets/steam/apps/321270/header.jpg?t=1728018391",
        "freeuntil": "expired"
    },
    {
        "gamename": "Ghostwire: Tokyo",
        "store": "Epic Games Store",
        "gamedescription": "Tokyo's population has vanished and deadly supernatural forces prowl the streets. Hone your otherwordly abilities to unravel the truth behind the disappearance and save the city. Explore brand-new single player content with the Spider's Thread update!",
        "gamelink": "https://store.epicgames.com/en-US/p/ghostwire-tokyo",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/a93cd6111251461c953776a0c4ea4d80/EGS_GhostwireTokyoWide_2560x1440-4bc1a83c819408d33e29e61f1a9d66e4?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Witch It",
        "store": "Epic Games Store",
        "gamedescription": "Witch It is a multiplayer hide & seek game set in a vibrant, magical world. Brave hunters seek hidden witches that curse their villages!",
        "gamelink": "https://store.epicgames.com/en-US/p/witch-it-3624a9",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/03887d7e03fd460eb1c3074d1de865dd/witch-it-ia44y.jpg?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Moving Out",
        "store": "Epic Games Store",
        "gamedescription": "Open your own Card shop by the seaside! Buy, trade and sell Cards, OR keep them for your own collection! Earn reputation with customers, beat your shop goals, unlock new decorations, and host exciting events to bring everyone to the best Card shop in town!",
        "gamelink": "https://store.epicgames.com/en-US/p/moving-out",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/f919a1262081444fb28f0fdef68d6b14/EGS_MovingOut_SMGStudioDevmGames_S1_2560x1440-86272cbdeb4cc4b39f0760ab720ac585?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Kardboard Kings",
        "store": "Epic Games Store",
        "gamedescription": "Open your own Card shop by the seaside! Buy, trade and sell Cards, OR keep them for your own collection! Earn reputation with customers, beat your shop goals, unlock new decorations, and host exciting events to bring everyone to the best Card shop in town!",
        "gamelink": " https://store.epicgames.com/en-US/p/kardboard-kings-0962f8",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/16244a18d39c4d30919ead91c7860397/kardboard-kings-logo-lof39.png?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Invincible Presents: Atom Eve",
        "store": "Epic Games Store",
        "gamedescription": "Become Atom Eve and take control of your own path as one of the most powerful superheroes in the Invincible universe! Unravel a mystery and balance the dangers and responsibilities of being a superhero with the relatable challenges of everyday life.\n",
        "gamelink": " https://store.epicgames.com/en-US/p/atom-eve-ff090d",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/bf00db0c53ad40f09bc1331b34cd58b6/atom-eve-logo-y47my.png?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Minimalism",
        "store": "Steam",
        "gamedescription": "Minimalism - platformer with a lot of levels, made in minimalist style - which is a cube protogonist. The game is a kind of labyrinth consisting of levels, to go between them you need to open the doors with collected keys.",
        "gamelink": "https://store.steampowered.com/app/585690/Minimalism/",
        "gamecoverurl": "https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/585690/header.jpg?t=1728420486",
        "freeuntil": "expired"
    },
    {
        "gamename": "Castle Break",
        "store": "Steam",
        "gamedescription": "Breakout game + physics + shooting + building. Use your mouse like in air hockey to kick enemy bombs back into the evil Black Castle! Destroy enemy towers rockets and lasers. Build your base to protect your little dudes - you need them to win",
        "gamelink": "https://store.steampowered.com/app/1018130/Castle_Break/",
        "gamecoverurl": "https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/1018130/header.jpg?t=1727210421",
        "freeuntil": "expired"
    },
    {
        "gamename": "Outliver: Tribulation",
        "store": "Epic Games Store",
        "gamedescription": "African Mythology meets Survival-Horror and Souls-like! You play as the soldier who mysteriously ends up in a supernatural realm, of which her only way out is to partake in an ancient ritual... or so she believes.",
        "gamelink": " https://store.epicgames.com/en-US/p/outliver-tribulations-679884",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/b3880830b9a54ae7bf9262ec56487f2f/outliver--tribulation-1hong.png?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Empyrion - Galactic Survival & Outliver",
        "store": "Epic Games Store",
        "gamedescription": "Build and explore in a true space sandbox survival adventure. Construct mighty capital vessels, sprawling planetary settlements, and vast space stations in your galactic journey to discover the mysteries of Empyrion.",
        "gamelink": " https://store.epicgames.com/en-US/p/empyrion-galactic-survival-fbd99f",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/25da1d05381345f6b50cca947a2fc2fc/empyrion--galactic-survival-1fmc8.jpg?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "Whispering Willows",
        "store": "GoG",
        "gamedescription": "Young Elena Elkhorn embarks on a harrowing journey to find her missing father and discover the secrets of the Willows Mansion. ",
        "gamelink": "https://www.gog.com/en/game/whispering_willows",
        "gamecoverurl": "https://images.gog-statics.com/5cd22404d6a037d2c1d5c030b9a6f279e2305161fb417df1690d7b3b16768c46_product_card_v2_logo_480x285.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Bear and Breakfast",
        "store": "Epic Games Store",
        "gamedescription": "Bear and Breakfast is a laid-back management adventure game where you build and run a bed and breakfast...but you’re a bear.",
        "gamelink": "https://store.epicgames.com/en-US/p/bear-and-breakfast-77c209",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/9f8b082e3cf84425be6aa563f0ddc2a0/bear-and-breakfast-logo-1yhmz.png?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Spirit and the Mouse",
        "store": "Epic Games Store",
        "gamedescription": "Bring kindness and light to the people of Sainte-et-Claire as Lila - a tiny mouse with a big heart! Explore a quaint French village, make electrifying new friends, and do good deeds for those in need in this lush narrative adventure game.",
        "gamelink": "https://store.epicgames.com/en-US/p/the-spirit-and-the-mouse-42390b",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/836b235a73124dff93ad3f6ec12dbaeb/the-spirit-and-the-mouse-logo-1x3fb.png?resize=1&w=480&h=270&quality=medium",
        "freeuntil": "expired"
    },
    {
        "gamename": "TOEM",
        "store": "Epic Games Store",
        "gamedescription": "Set off on a delightful expedition and use your photographic eye to uncover the mysteries of the magical TOEM in this hand-drawn adventure game. Chat with quirky characters and solve their problems by snapping neat photos!",
        "gamelink": "https://store.epicgames.com/en-US/p/toem",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-toem-somethingwemade-ic1-400x176-d6ea2140ccab.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Last Stand: Aftermath",
        "store": "Epic Games Store",
        "gamedescription": "From the creators of The Last Stand: Union City comes a new singleplayer rogue-lite action adventure. After you are infected by the zombie virus, set out to explore the apocalypse and find hope for your colony. You can make a difference. Don't give up.",
        "gamelink": "https://store.epicgames.com/en-US/p/the-last-stand-aftermath",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-thelaststandaftermath-conartistgames-ic1-400x189-c6de29e28e42.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "SUPER CRAZY RHYTHM CASTLE",
        "store": "Epic Games Store",
        "gamedescription": "It’s Super Crazy Rhythm Castle, the chaotic rhythm adventure! A puzzling co-op mashup unlike anything you've ever heard – work together to think outside the box while keeping your combo. Solo, or with a band of up to three friends … … Can you conquer the castle?",
        "gamelink": "https://store.epicgames.com/en-US/p/super-crazy-rhythm-castle-e3b464",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/3e4cea34f89d440d97cf22ddf6c122f2/super-crazy-rhythm-castle-166b4.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Rugrats: Adventures in Gameland",
        "store": "Epic Games Store",
        "gamedescription": "When Tommy, Chuckie, Phil and Lil see a commercial for the new Reptar video game, they pretend that they're in a video game of their own! Toggle between beautiful 8-bit and HD art, select your favorite baby and play solo or co-op!",
        "gamelink": "https://store.epicgames.com/en-US/p/rugrats-adventures-in-gameland-737402",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/1251ee32f3b6496e99e170d4dc890d9e/rugrats-adventures-in-gameland-53l09.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Sniper Ghost Warrior Contracts",
        "store": "Epic Games Store",
        "gamedescription": "Become the ultimate assassin in the most realistic modern warfare sniping game available. Engage in an immersive single-player campaign across large, open-ended maps. Tactical thinking, stealth approach and precision in execution of long shots required.",
        "gamelink": "https://store.epicgames.com/en-US/p/sniper-ghost-warrior-contracts-c772b9",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/4ad9b2d4a92141a09e3ca4df8bfbcfea/sniper-ghost-warrior-contracts-logo-1l49s.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Football Manager 2024",
        "store": "Epic Games Store",
        "gamedescription": "Craft a world-class team ready to dominate your rivals in football’s most prestigious competitions. Progress never stops when you’re pursuing footballing greatness.",
        "gamelink": "https://store.epicgames.com/en-US/p/football-manager-2024",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/610a546d4e204215a0b9a1c8a382bacb/EGS_FootballManager2024_SportsInteractive_S1_2560x1440-3f607ee3653065afb4ae73fcb5ff5e0c?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Once Upon a Jester",
        "store": "GoG",
        "gamedescription": "Best friends Sok & Jester devise a mischievous scheme to steal the Royal Diamond! To complete their master plan, they must start an improv theatre show and outperform every other act - including their rivals, Hofnar & Bok.",
        "gamelink": "https://www.gog.com/game/once_upon_a_jester",
        "gamecoverurl": "https://images.gog-statics.com/df8b386be1ef2cddf659b72dec7d79350f2047b0d48ca9574c1c39d8ee1746ad_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Wild Card Football",
        "store": "Epic Games Store",
        "gamedescription": "Wild Card Football is a fun pick-up-and-play arcade-style football game featuring 7v7 team-focused gameplay with the unique Wild Card system that lets you change the course of the action in an instant with special power-ups and rule-breaking abilities.",
        "gamelink": "https://store.epicgames.com/en-US/p/wild-card-football",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-wildcardfootball-saberinteractive-ic1-400x270-de99027e27fd.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Fallout - Classic Collection",
        "store": "Epic Games Store",
        "gamedescription": "You've just unearthed the classic post-apocalyptic role-playing game that revitalized the entire CRPG genre. The Fallout® Classic Collection contains the following content: Fallout®, Fallout® 2, Fallout® Tactics: Brotherhood of Steel",
        "gamelink": "https://store.epicgames.com/en-US/bundles/fallout-classic-collection",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-falloutclassiccollection-bethesdagamestudios-ic1-400x400-206540d1af36.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Gigantic: Rampage Edition",
        "store": "Epic Games Store",
        "gamedescription": "GIGANTIC: RAMPAGE EDITION is a premium and definitive release of the original 5v5 MOBA Hero Shooter, GIGANTIC. Choose a unique hero and team up with other players to control objectives. GIGANTIC: RAMPAGE EDITION includes a new game mode, cross platform play, new heroes, new maps.",
        "gamelink": "https://store.epicgames.com/en-US/p/gigantic-rampage-edition-03f532",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/6630e3dcc72a4e2285e91ca48aa0eb4b/gigantic-rampage-edition-logo-pbydw.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Callisto Protocol",
        "store": "Epic Games Store",
        "gamedescription": "Survive to escape the horrors of Callisto. Using a unique blend of shooting and close-quarters combat, players will need to combat rapidly evolving creatures while scavenging to unlock new weapons and gear to outrun the growing threat of Jupiter's Dead Moon.",
        "gamelink": "https://store.epicgames.com/en-US/p/the-callisto-protocol",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-thecallistoprotocol-strikingdistancestudios-ic1-400x400-ee5f43879f4e.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Death's Gambit: Afterlife",
        "store": "Epic Games Store",
        "gamedescription": "Death's Gambit: Afterlife is a challenging 2D action platformer with deep RPG elements. As an agent of Death bound to his service, unravel the mystery of Siradon and discover the true price of immortality.",
        "gamelink": "https://store.epicgames.com/en-US/p/deaths-gambit-afterlife-b5f411",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/58c8925d94e440c7b0b4206635272579/deaths-gambit--afterlife-tvf4z.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "CYGNI: All Guns Blazing",
        "store": "Epic Games Store",
        "gamedescription": "An unrelenting onslaught of eye-popping visuals, ear bursting soundscapes and mind-melting action makes CYGNI the vanguard for the next generation of shoot-em ups. Outgunned, outmanned and out on your own, plunge into a sky full of hell in a last-ditch battle for survival.",
        "gamelink": "https://store.epicgames.com/en-US/p/cygni-all-guns-blazing-98f872",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/99e568930ad045a5a91b0b50ea3d5389/cygni--all-guns-blazing-logo-9z2et.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Drawful 2",
        "store": "Steam",
        "gamedescription": "For 3-8 players and an audience of thousands! Your phones or tablets are your controllers! The game of terrible drawings and hilariously wrong answers.",
        "gamelink": "https://store.steampowered.com/app/442070/Drawful_2/",
        "gamecoverurl": "https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/442070/header.jpg?t=1721927113",
        "freeuntil": "expired"
    },
    {
        "gamename": "LumbearJack",
        "store": "Epic Games Store",
        "gamedescription": "Grab your axe and save the environment by chopping and recycling every man-made thing in your path and solving puzzles with your charming animal friends!",
        "gamelink": "https://store.epicgames.com/en-US/p/lumbearjack-b5bedf",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/67b80cb431834a1fa1ec3e69ef2f83f1/lumbearjack-logo-140ac.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "F.I.S.T.: Forged In Shadow Torch",
        "store": "Epic Games Store",
        "gamedescription": "Explore more than a dozen unique areas in a Metroidvania style map. The Fist, Drill, and Whip are three weapons with completely different fighting styles that offer seamless switching between high combo, high damage, and long range attacks.",
        "gamelink": "https://store.epicgames.com/en-US/p/fist-forged-in-shadow-torch",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/a7e2a2c51b9149c097b771926ed91877/EGS_FISTForgedInShadowTorch_TiGames_S3_2560x1440-d79b811e274bd49318edf3b7c00b82c8?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Fallout: London",
        "store": "GoG",
        "gamedescription": "Fallout: London lets you explore a dishevelled London set in the Fallout universe. It overhauls Fallout 4 with a new story, new weapons, London-centric items and a vast recreation of the ruined city to explore.",
        "gamelink": "https://www.gog.com/en/game/fallout_london",
        "gamecoverurl": "https://images.gog-statics.com/c5c92ae12f9e7f51a49f3fe81b5f535c95a2a5b470a2af631740aeb06f9c6691_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Maid of Sker",
        "store": "Epic Games Store",
        "gamedescription": "Maid of Sker is a first-person survival horror, set in a remote hotel with a gory and macabre history from British folklore. Brave the nightmares of the Quiet Ones. Do not panic…don’t even breathe!",
        "gamelink": "https://store.epicgames.com/en-US/p/maid-of-sker-2ae24e",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/964693002c1b447c949b155c71727c45/maid-of-sker-logo-13s8k.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Arcade Paradise",
        "store": "Epic Games Store",
        "gamedescription": "Welcome to Arcade Paradise, the 90’s-fuelled retro arcade adventure. You play as Ashley, and you’ve just been given the keys to the family laundromat. Rather than washing rags for a living, you decide to turn the laundromat into the ultimate arcade.",
        "gamelink": "https://store.epicgames.com/en-US/p/arcade-paradise",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-arcadeparadise-nosebleedinteractive-ic1-400x160-c50cf22a5394.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Floppy Knights",
        "store": "Epic Games Store",
        "gamedescription": "Tactics fuse with card game mechanics as Phoebe and Carlton, a brilliant young inventor & her robot-arm bestie, square off in turn-based battles. Select your Knights, hone your deck, and execute your strategy for victory!",
        "gamelink": "https://store.epicgames.com/en-US/p/floppy-knights-6a735a",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/e6d8370167ce4fe99a69d195174f6208/floppy-knights-rnv64.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Falconeer",
        "store": "Epic Games Store",
        "gamedescription": "Soar through the skies aboard a majestic warbird, explore a stunning oceanic world and engage in epic aerial dogfights, in this BAFTA nominated air combat game from solo developer, Tomas Sala.",
        "gamelink": "https://store.epicgames.com/en-US/p/the-falconeer",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-thefalconeer-tomassala-ic1-400x239-d52145dddd55.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "The First Templar - Special Edition",
        "store": "GoG",
        "gamedescription": "The First Templar follows the story of two main characters – a French Templar, and his companion, a noble lady who has been proclaimed a heretic. Taking control over these two unlikely allies, the player must uncover the mysteries behind the Templar Order, play a role in a grand conspiracy, and discover the secret of the Holy Grail.",
        "gamelink": "https://www.gog.com/en/game/the_first_templar",
        "gamecoverurl": "https://images.gog-statics.com/370a1d8de80711dd497dd8887f03d8b15345ae15515b2f463c95c9ab4d4acee0_product_card_v2_mobile_slider_1710.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Sunless Skies: Sovereign Edition",
        "store": "Epic Games Store",
        "gamedescription": "Place conveyor belts to solve puzzles in the world's most adorable donut factory!",
        "gamelink": "https://store.epicgames.com/en-US/p/sunless-skies-bb4947",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/c940bf4524614968826999d859f79dbd/sunless-skies-1v5ll.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Freshly Frosted",
        "store": "Epic Games Store",
        "gamedescription": "Place conveyor belts to solve puzzles in the world's most adorable donut factory!",
        "gamelink": "https://store.epicgames.com/en-US/p/freshly-frosted-c636d0",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/8856ac3296214cc086bc101382ee8ffa/freshly-frosted-offer-1tpl2.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Islands of Insight",
        "store": "Steam",
        "gamedescription": "Seek out and solve 10,000+ puzzles at your own pace across this sublime shared-world puzzle game.",
        "gamelink": "https://store.steampowered.com/app/2071500/Islands_of_Insight/",
        "gamecoverurl": "https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/2071500/header.jpg?t=1716489364",
        "freeuntil": "expired"
    },
    {
        "gamename": "Redout 2",
        "store": "Epic Games Store",
        "gamedescription": "The fastest racing game in the universe. Redout 2 is a tribute to classic arcade racing games and the sequel to the critically acclaimed Redout, where racing through the dystopian wastelands of a semi-abandoned Earth is one of the galaxy’s most popular sports.",
        "gamelink": "https://store.epicgames.com/en-US/p/redout-2",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-redout2-34bigthings-ic1-400x74-38c30295d852.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Marvel's Midnight Suns",
        "store": "Epic Games Store",
        "gamedescription": "Confront the darker side of the Marvel universe in an all-new Tactical RPG featuring Iron Man, Wolverine, Captain America, Ghost Rider, Blade, and more.",
        "gamelink": "https://store.epicgames.com/en-US/p/marvels-midnight-suns",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/ea73c08ac633414584e281f838b3a78c/EGS_MarvelsMidnightSuns_FiraxisGames_S1_2560x1440-2f3d597f3afd9e9fd4cea1df3394705e?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Chivalry 2",
        "store": "Epic Games Store",
        "gamedescription": "Chivalry 2 is a multiplayer first person slasher inspired by epic medieval movie battles. Players are thrust into the action of every iconic moment of the era - from clashing swords, to storms of flaming arrows, to sprawling castle sieges and more.",
        "gamelink": "https://store.epicgames.com/en-US/p/chivalry-2",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-chivalry2-tornbannerstudios-ic1-200x68-8eb7d78ce1c4.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Farming Simulator 22",
        "store": "Epic Games Store",
        "gamedescription": "Take on the role of a modern farmer and creatively build your farm in three diverse American and European environments. Farming Simulator 22 offers a huge variety of farming operations focusing on agriculture, animal husbandry and forestry - now with the exciting addition of seasonal cycles!",
        "gamelink": "https://store.epicgames.com/en-US/p/farming-simulator-22",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/c93edfff-e8d3-4c0d-855b-03f44f1d9cd3_2560x1440-79fcb25480b4c1faf67a97207b97b7e2?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "ENDLESS Legend",
        "store": "Epic Games Store",
        "gamedescription": "ENDLESS™ Legend is a 4X turn-based fantasy strategy game by the creators of ENDLESS™ Space and Dungeon of the ENDLESS™. Control every aspect of your civilization as you struggle to save your homeworld Auriga. Create your own Legend!",
        "gamelink": "https://store.steampowered.com/app/289130/ENDLESS_Legend/",
        "gamecoverurl": "https://cdn.cloudflare.steamstatic.com/steam/apps/289130/header.jpg?t=1715276566",
        "freeuntil": "expired"
    },
    {
        "gamename": "Dragon Age: Inquisition – Game of the Year Edition",
        "store": "Epic Games Store",
        "gamedescription": "Winner of over 130 Game of the Year awards, discover the definitive Dragon Age: Inquisition experience. The Game of the Year Edition includes the critically acclaimed game, all three official add-ons - Jaws of Hakkon, The Descent, and Trespasser - and more.",
        "gamelink": "https://store.epicgames.com/en-US/p/dragon-age-inquisition-game-of-the-year-edition-2f0cbd",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/175e781d820144ce966f1c1942b64b57/dragon-age-inquisition-game-of-the-year-edition-logo-18dia.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Machinika: Museum",
        "store": "Steam",
        "gamedescription": "Welcome to the Museum! Machinika Museum is a mystery game where you discover beautiful and mysterious machines of extra-terrestrial origins in an atmospheric galaxy-spanning 3D puzzle game. Will you be able to understand their stories and discover what happened to their previous owner?",
        "gamelink": "https://store.steampowered.com/app/1507190/Machinika_Museum/",
        "gamecoverurl": "https://cdn.cloudflare.steamstatic.com/steam/apps/1507190/header.jpg?t=1715620075",
        "freeuntil": "expired"
    },
    {
        "gamename": "Circus Electrique",
        "store": "Epic Games Store",
        "gamedescription": "Circus Electrique is part story-driven RPG, part tactics, part circus management, and completely enthralling. When everyday Londoners mysteriously turn into vicious killers, only the show’s talented performers possess the skills necessary to save the city.",
        "gamelink": "https://store.epicgames.com/en-US/p/circus-electrique",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/396a808f269241aba0fe2ad322ebd6d5/EGS_CircusElectrique_ZenStudios_S1_2560x1440-0f035a36376dd51b907662f4cd121553?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "CAT QUEST II",
        "store": "Epic Games Store",
        "gamedescription": "Open-world action-RPG in a fantasy realm of cats and dogs. CAT QUEST II lets you play solo or with a friend, as both a cat and dog! Quest in a world filled with magic, defeat monsters and collect loot in a catventure like never before!",
        "gamelink": "https://store.epicgames.com/en-US/p/cat-quest-ii-9dbefc",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/fe812f94c42e44e986691a84c796952d/cat-quest-ii-logo-1adtp.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "ORCS MUST DIE! 3 ",
        "store": "Epic Games Store",
        "gamedescription": "Slice, burn, toss, zap, grind and gib massive hordes of repugnant orcs in this long-awaited successor to the award-winning Orcs Must Die! series.",
        "gamelink": "https://store.epicgames.com/en-US/p/combo-a7e03a",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/af503dd7e8b64218b69c22a9003de268/orcs-must-die-3-logo-1nm2v.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "INDUSTRIA",
        "store": "Epic Games Store",
        "gamedescription": "INDUSTRIA is a first-person shooter that takes you from East Berlin into a parallel reality, shortly before the end of the Cold War. On the search for a missing work colleague, you decypher a dark past in a mysterious parallel dimension.",
        "gamelink": "https://store.epicgames.com/en-US/p/industria",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-industria-bleakmill-ic1-400x159-a2fadd34c97c.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "LISA: The Definitive Edition",
        "store": "Epic Games Store",
        "gamedescription": "Explore Olathe and unmask its twisted secrets with LISA: The Definitive Edition, including the original narrative-driven RPG LISA: The Painful and its sequel LISA: The Joyful.",
        "gamelink": "https://store.epicgames.com/en-US/bundles/lisa-the-definitive-edition",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-lisathedefinitiveedition-dingalingproductions-bundles-ic1-200x200-e2c5c1f93cdb.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Town of Salem",
        "store": "Epic Games Store",
        "gamedescription": "Step into the Town of Salem, a realm of Mystery and Deceit awaits! Join us in this thrilling online strategy game. Experience paranoia, taste triumph, and confront Murder in every move!",
        "gamelink": "https://store.epicgames.com/en-US/p/town-of-salem-2-ab6497",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/5461784e424b43709943f14954a9f83f/town-of-salem-2-logo-1ss8k.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Big Con",
        "store": "Epic Games Store",
        "gamedescription": "Hustle and grift your way across America in the ‘90s as a teenage con artist on a crime-filled road trip adventure! Play as Ali, a curious and sarcastic high schooler who ditches band camp to go on a cross-country road trip to save her family video store.",
        "gamelink": "https://store.epicgames.com/en-US/p/the-big-con",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-thebigcon-mightyyell-ic1-400x400-02bfa5870a9b.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Ghostrunner",
        "store": "Epic Games Store",
        "gamedescription": "As the most advanced blade fighter ever created, you’re always outnumbered but never outclassed. Slice your enemies with a monomolecular katana, dodge bullets with your superhuman reflexes, and employ a variety of specialized techniques to prevail.",
        "gamelink": "https://store.epicgames.com/p/ghostrunner",
        "gamecoverurl": "https://cdn2.unrealengine.com/gr-epic-dtcarousel-440x248-440x248-017852145.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Outer Worlds: Spacer's Choice Edition",
        "store": "Epic Games Store",
        "gamedescription": "The Outer Worlds: Spacer’s Choice Edition is the ultimate way to play the award-winning RPG from Obsidian Entertainment and Private Division. Including the base game and all DLC, this remastered masterpiece is the absolute best version of The Outer Worlds.",
        "gamelink": "https://store.epicgames.com/en-US/p/the-outer-worlds-spacers-choice-edition",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/dc61166eea95474e912953b163791d42/EGS_TheOuterWorldsSpacersChoiceEdition_ObsidianEntertainment_S1_2560x1440-dd9211a8277a2392a9dd5b108858ba33?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Thief",
        "store": "Epic Games Store",
        "gamedescription": "Thief is a 1st person stealth-action game by Eidos-Montréal. In this reimagination of the cult classic Thief franchise, steal, stealth and infiltrate your way through the treacherous City as Garrett, the Master Thief.",
        "gamelink": "https://store.epicgames.com/en-US/p/thief-5bb95f",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/44b12bc6a7f045a3bf313574c344dfd7/thief-ms1j4.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Content Warning",
        "store": "Steam",
        "gamedescription": "Film your friends doing scary things to become SpookTube famous!",
        "gamelink": "https://store.steampowered.com/app/2881650/Content_Warning/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/2881650/header.jpg?t=1711987881",
        "freeuntil": "expired"
    },
    {
        "gamename": "Islets",
        "store": "Epic Games Store",
        "gamedescription": "Take to the sky and reunite a fragmented world in this surprisingly wholesome metroidvania! Help Iko adventure across beautiful hand-painted islands, receive letters from a quirky cast of characters, and face powerful monstrous adversaries.",
        "gamelink": "https://store.epicgames.com/en-US/p/islets-5f2670",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/f991a978e0ce4156a52f951e96e388e7/islets-logo-i4ccz.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Call of the Wild: The Angler",
        "store": "Epic Games Store",
        "gamedescription": "From the creators of theHunter: Call of the Wild comes an open world fishing experience! Relax and unwind at your favorite spot or explore the beautiful great outdoors with friends as you embark on the journey to become a master angler.",
        "gamelink": "https://store.epicgames.com/en-US/p/call-of-the-wild-the-angler",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/75d74aeb36024d95b8a30bad40dc19c3/EGS_CalloftheWildTheAngler_ExpansiveWorlds_S1_2560x1440-1cbd50dac3db204690de80ff6d154fc7?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Invincible Presents: Atom Eve",
        "store": "Epic Games Store",
        "gamedescription": "Become Atom Eve and take control of your own path as one of the most powerful superheroes in the Invincible universe! Unravel a mystery and balance the dangers and responsibilities of being a superhero with the relatable challenges of everyday life.",
        "gamelink": "https://store.epicgames.com/en-US/p/atom-eve-ff090d",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/bf00db0c53ad40f09bc1331b34cd58b6/atom-eve-nfh8q.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Bridge",
        "store": "Epic Games Store",
        "gamedescription": "The Bridge is a logic puzzle game that forces the player to reevaluate their preconceptions of physics and perspective. It is Isaac Newton meets M.C. Escher. Manipulate gravity to redefine the ceiling as the floor while venturing through impossible architectures.",
        "gamelink": "https://store.epicgames.com/en-US/p/the-bridge",
        "gamecoverurl": "https://cdn2.unrealengine.com/Diesel/productv2/the-bridge/home/The_Bridge_Title_Logo-1424x915-0d1509ff634ea69465a39947cf1058b2dbc17788.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Deus Ex: Mankind Divided",
        "store": "Epic Games Store",
        "gamedescription": "Deus Ex: Mankind Divided is a cyberpunk action-RPG where your choices have lasting consequences. You play as Adam Jensen, an augmented covert operative intent on taking down the Illuminati, a shadowy group conspiring for control over humanity’s future.",
        "gamelink": "https://store.epicgames.com/en-US/p/deus-ex-mankind-divided-4c6370",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/c47ffdc5b1cb42fc83bd9ea42861b800/deus-ex--mankind-divided-logo-1cxes.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Astro Duel 2",
        "store": "Epic Games Store",
        "gamedescription": "Astro Duel 2 is a sci-fi combat game combining top-down space dog fights with up-close platforming action. Whether versus or co-op, battle through space and on foot at the same time! Strike within the fully-destructible arenas or just nuke ‘em from orbit.",
        "gamelink": "https://store.epicgames.com/en-US/p/astro-duel-2-e1261d",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/3251b927449a471c883aab00c076d63c/astro-duel-2-logo-4fuly.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Space Crew: Legendary Edition",
        "store": "Steam",
        "gamedescription": "Take the role of Wally. A mysterious character that has recovered what was taken from him. Hopefully, you're fast enough to outrun your enemies. Run, Jump, Slide or Dash for acrobatic variants leading to dope combinations and avoid the challenges that await.",
        "gamelink": "https://store.steampowered.com/app/1176710/Space_Crew_Legendary_Edition/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/1176710/header.jpg?t=1709034761",
        "freeuntil": "expired"
    },
    {
        "gamename": "Aerial_Knight's Never Yield",
        "store": "Epic Games Store",
        "gamedescription": "Take the role of Wally. A mysterious character that has recovered what was taken from him. Hopefully, you're fast enough to outrun your enemies. Run, Jump, Slide or Dash for acrobatic variants leading to dope combinations and avoid the challenges that await.",
        "gamelink": "https://store.epicgames.com/en-US/p/aerial-knights-never-yield",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-aerial-knightsneveryield-aerial-knight-ic1-400x279-e8f5a26229c1.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Nomads of Driftland",
        "store": "GoG",
        "gamedescription": "The promise to restore civilization to its former shape and return to the times of splendor overcame sadness. It filled the hearts of Driftland's inhabitants with new hope and energy. Determination for action diverted attention from the numerous conflicts between quarrelsome mage-rulers and various injuries sustained by those who did not give in to their will.",
        "gamelink": "https://www.gog.com/en/game/nomads_of_driftland",
        "gamecoverurl": "https://images.gog-statics.com/cada197b5b2d616c2f83b7b30a2d844ab027e496ce29d43b1021c21786ffdc7b_product_card_v2_logo_480x285.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Super Meat Boy Forever",
        "store": "Epic Games Store",
        "gamedescription": "The challenge of Super Meat Boy returns in Super Meat Boy Forever! Levels are brutal and death is inevitable. Players will run, jump, punch and kick their way through familiar places and new worlds all while enjoying a story truly meant for the big screen.",
        "gamelink": "https://store.epicgames.com/en-US/p/super-meat-boy-forever",
        "gamecoverurl": "https://cdn1.epicgames.com/moose/offer/EGS_SuperMeatBoyForever_TeamMeat_S1-2560x1440-76f57fe5321b054665441ce21231961e.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Dakar Desert Rally",
        "store": "Epic Games Store",
        "gamedescription": "Dakar Desert Rally is the biggest off-road rally racing adventure ever developed. Experience the speed and excitement of the largest rally race on the planet, featuring a wide variety of licensed vehicles from motorcycles, cars, trucks, quads and SSVs.",
        "gamelink": "https://store.epicgames.com/en-US/p/dakar-desert-rally",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/ac98aa34584648f2b71152e509191c1c/EGS_DakarDesertRally_SaberPortoLtd_S3_2560x1440-ffccd2b231c717b9e1e7cd10049f84aa?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Flatout",
        "store": "Steam",
        "gamedescription": "Drivers thrown across the track, shattered fences, mangled cars, exploding tire walls, and that's just the first corner! Use every trick, shortcut and jump as you battle 7 rivals for the championship.",
        "gamelink": "https://www.gog.com/game/flatout",
        "gamecoverurl": "https://images.gog-statics.com/75520aaf2c55d6567f0751cb27f65ac5d1b850296625e70ca35512bbc2891011_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Dead Island: Riptide Definitive Edition",
        "store": "Steam",
        "gamedescription": "Welcome to the zombie apocalypse experience of a lifetime – more beautiful than ever. Caught in an epic zombie outbreak on the tropical island of Banoi, your only thought is: Survive!",
        "gamelink": "https://store.steampowered.com/app/383180/Dead_Island_Riptide_Definitive_Edition/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/383180/header.jpg?t=1707842172",
        "freeuntil": "expired"
    },
    {
        "gamename": "Lost Castle",
        "store": "Epic Games Store",
        "gamedescription": "Lost Castle is an action RPG beat'em up with roguelike elements and randomized dungeons for up to four players online and local!",
        "gamelink": "https://store.epicgames.com/en-US/p/lost-castle-abb2e2",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/a6d76157ad884f2c9aa470b30da9e2ff/lost-castle-1xivc.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Doki Doki Literature Club Plus",
        "store": "Epic Games Store",
        "gamedescription": "Welcome to the club! Write poems for your crush and experience the terror of school romance in this critically-acclaimed psychological horror stor y.",
        "gamelink": "https://store.epicgames.com/en-US/p/doki-doki-literature-club-plus",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_DokiDokiLiteratureClubPlus_TeamSalvato_S1_2560x1440-11bf3498c7d817623a070b9b4ac84288?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Doors: Paradox",
        "store": "Epic Games Store",
        "gamedescription": "A relaxing puzzle escape game! Make your way through a variety of hand-crafted 3D dioramas, look for useful objects, find hidden clues and solve fun puzzles! Unravel this mysterious adventure about chaos, order and the surreal.",
        "gamelink": "https://store.epicgames.com/en-US/p/doors-paradox-5896c7",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/27da8c9da5774ade943fb2d5490bfc99/doors--paradox-1yr08.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Party Animals - Friend's Pass",
        "store": "GoG",
        "gamedescription": "The Beast Inside is a unique, gripping twist on thriller and survival horror. The game mixes intriguing story-driven adventure with unnerving survival horror experience. Immerse in the tale of long-buried secrets, personal tragedies, and madness. Play as two protagonists living in different centuries but bounded by dark heritage.",
        "gamelink": "https://www.gog.com/game/the_beast_inside",
        "gamecoverurl": "https://images.gog-statics.com/fb83be213c76c9c411a25e96b667d1519593fc84b0023e5ffcc4623ed7d61b2b_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Party Animals - Friend's Pass",
        "store": "Steam",
        "gamedescription": "Fight your friends as puppies, kittens and other fuzzy creatures in PARTY ANIMALS! Paw it out with your friends remotely, or huddle together for chaotic fun on the same screen. Interact with the world under our realistic physics engine. Did I mention PUPPIES?",
        "gamelink": "https://store.steampowered.com/app/1260320/Party_Animals/",
        "gamecoverurl": "https://cdn.cloudflare.steamstatic.com/steam/apps/1260320/header.jpg?t=1701692409",
        "freeuntil": "expired"
    },
    {
        "gamename": "Mordheim: City of the Damned",
        "store": "GoG",
        "gamedescription": "Play the first video game adaptation of Games Workshop's tabletop game Mordheim. Set in the Warhammer World's decimated Empire city, Mordheim: City of the Damned is a turn-based tactical game where you lead warbands into bloody and lethal skirmishes.",
        "gamelink": "https://www.gog.com/game/mordheim_city_of_the_damned",
        "gamecoverurl": "https://images.gog-statics.com/f5999efbdf1dd6f296b319e110fa21231132d4e42edb5cc8904e3356e883928c_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Infinifactory",
        "store": "Epic Games Store",
        "gamedescription": "Infinifactory is a sandbox puzzle game by Zachtronics, the creators of SpaceChem and Infiniminer. Build factories that assemble products for your alien overlords, and try not to die in the process.",
        "gamelink": "https://store.epicgames.com/en-US/p/infinifactory-ec9686",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/006ad04cca8f4cd89d80210e142abb89/infinifactory-222r8.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Love",
        "store": "Epic Games Store",
        "gamedescription": "Love is a reductive platforming game with a retro aesthetic and a focus on a challenging difficulty. It has an custom respawn system, 16 levels, 12 track soundtrack, and competitive scoring.",
        "gamelink": "https://cdn1.epicgames.com/spt-assets/d4c3a9a3058c471fb5e18af6908f28f0/love-logo-cz13p.png?h=270&quality=medium&resize=1&w=480",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/d4c3a9a3058c471fb5e18af6908f28f0/love-logo-cz13p.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Sail Forth",
        "store": "Epic Games Store",
        "gamedescription": "Sail a fleet of customizable ships across an ocean whose ancient depths have swallowed the key to the mysterious past. Befriend fishfolk to expand your boat crews, take up arms against petulant pirates, and chart a course across an expansive watery world.",
        "gamelink": "https://store.epicgames.com/p/sail-forth-51847e",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/bf6f7c896b214dd891aa10debb6fbf50/sail-forth-logo-8cwm7.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Marvel's Guardians of the Galaxy",
        "store": "Epic Games Store",
        "gamedescription": "You are Star-Lord. The fate of the galaxy and the Guardians is in your hands. Time to show the universe what you’re made of. You got this. Probably.",
        "gamelink": "https://store.epicgames.com/p/marvels-guardians-of-the-galaxy",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/2a09fb19b47f46dfb11ebd382f132a8f/EGS_MarvelsGuardiansoftheGalaxy_EidosMontral_S1_01_2560x1440-41457c6140198776b348b1c77e730e48?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "A Plague Tale: Innocence",
        "store": "Epic Games Store",
        "gamedescription": "Follow the grim tale of young Amicia and her little brother Hugo, in a heartrending journey through the darkest hours of history. Hunted by Inquisition soldiers and surrounded by unstoppable swarms of rats, they will come to know and trust each other.",
        "gamelink": "https://store.epicgames.com/p/a-plague-tale-innocence",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/1bbe911a-6c89-490c-9230-cdd99c3be4b5_2560x1440-1c47786e1130e040a090368a1551a627?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "20 Minutes Till Dawn",
        "store": "Epic Games Store",
        "gamedescription": "20 Minutes Till Dawn is a survival roguelite where endless hordes of creatures lurk from the dark. Craft an array of overpowering builds and eradicate waves of Lovecraftian nightmares. Will you be able to survive the night?",
        "gamelink": "https://store.epicgames.com/en-US/p/20-minutes-till-dawn-be3305",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/e35e2b0c2411423fb6cd97c1dda51e07/20-minutes-till-dawn-huwun.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Escape Academy",
        "store": "Epic Games Store",
        "gamedescription": "Welcome to Escape Academy. Train to become the ultimate Escapist. Solve Puzzles. Hack Servers. Meet the Faculty. Brew the perfect cup of tea. Escape Rooms in single player or co-op with a friend - local or online!",
        "gamelink": "https://store.epicgames.com/p/escape-academy-bfc2bf",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/9da72b3af32d4818b502369a4aac5aac/escape-academy-logo-10ldp.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Ghostrunner",
        "store": "Epic Games Store",
        "gamedescription": "Ghostrunner offers a unique single-player experience: fast-paced, violent combat, and an original setting that blends science fiction with post-apocalyptic themes. It tells the story of a world that has already ended and its inhabitants who fight to survive.",
        "gamelink": "https://store.epicgames.com/p/ghostrunner",
        "gamecoverurl": "https://cdn2.unrealengine.com/gr-epic-dtcarousel-440x248-440x248-017852145.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Saints Row",
        "store": "Epic Games Store",
        "gamedescription": "Welcome to Santo Ileso, a vibrant fictional city in the American Southwest. In a world rife with crime, a group of young friends embark on their own criminal venture, as they rise to the top in their bid to become Self Made.",
        "gamelink": "https://store.epicgames.com/p/saints-row",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-saintsrow-deepsilvervolition-ic3-400x210-07f57b30df7e.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Snakebird Complete",
        "store": "Epic Games Store",
        "gamedescription": "Introducing Snakebird Complete: The Ultimate Snakebird Experience! Embark on an extraordinary puzzle-solving adventure bringing together hit classic Snakebird and Snakebird Primer, offering the complete Snakebird experience in a single, comprehensive package",
        "gamelink": "https://store.epicgames.com/en-US/p/snakebird-complete-e6f0ae",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/8ecb2895d94f4c64a743c1c4f056743f/snakebird-complete-logo-c80d0.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Cursed to Golf",
        "store": "Epic Games Store",
        "gamedescription": "Cursed to Golf is a golf-like adventure where every shot counts. Players are tasked with making it out of Golf Purgatory to become a Golfing Legend.",
        "gamelink": "https://store.epicgames.com/en-US/p/cursed-to-golf-a6bc22",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/370dbdf8c5e44dc09eac477fd43e8aee/cat-quest-logo-llq2w.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Cat Quest",
        "store": "Epic Games Store",
        "gamedescription": "The multi award-winning Action RPG. Cat Quest's loot-fuelled cat-venture is as beautifully crafted, as its combat and magic are ultra-satisfying. Universally adored by critics - it'll have you purring for joy!",
        "gamelink": "https://store.epicgames.com/p/cat-quest-8373e0",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/a323e5521a98467dbbd24aa9bb3ee9a4/cursed-to-golf-logo-j0bjc.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Human Resource Machine",
        "store": "Epic Games Store",
        "gamedescription": "Human Resource Machine is a puzzle game for nerds. In each level, your boss gives you a job. Automate it by programming your little office worker. If you succeed, you'll be promoted up to the next level for another year of work in the vast office building.",
        "gamelink": "https://store.epicgames.com/en-US/p/human-resource-machine",
        "gamecoverurl": "https://cdn2.unrealengine.com/Diesel%2Fproduct%2Fbasil%2Fhome%2FHumanResourceMachine_Logo-1191x870-84c291a4b6f4404ee13581bd4993e5c776cabd38.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Outer Worlds: Spacer's Choice Edition",
        "store": "Epic Games Store",
        "gamedescription": "The Outer Worlds: Spacer’s Choice Edition is the ultimate way to play the award-winning RPG from Obsidian Entertainment and Private Division. Including the base game and all DLC, this remastered masterpiece is the absolute best version of The Outer Worlds.",
        "gamelink": "https://store.epicgames.com/en-US/p/the-outer-worlds-spacers-choice-edition",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-theouterworldsspacerschoiceedition-obsidianentertainment-ic1-400x400-86c3bee5e2d2.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Fallout 3: Game of the Year Edition",
        "store": "Epic Games Store",
        "gamedescription": "Prepare for the Future™ Experience the most acclaimed game of 2008 like never before with Fallout 3: Game of the Year Edition. Create a character of your choosing and descend into a post-apocalyptic world where every minute is a fight for survival",
        "gamelink": "https://store.epicgames.com/en-US/p/fallout-3-game-of-the-year-edition",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-fallout3gameoftheyearedition-bethesdagamestudios-ic1-400x400-739792e4f256.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Caveblazers",
        "store": "GoG",
        "gamedescription": "Caveblazers is an action focused platformer roguelike set in a fantasy world. Each game is unique with procedurally generated levels to explore and a massive amount of items, weapons and equipment to discover. The game takes place in a recently discovered cave, said to hold unimaginable power",
        "gamelink": "https://www.gog.com/game/caveblazers",
        "gamecoverurl": "https://images.gog-statics.com/ee37e0bb054cc9a65bc3a8f62efd4336b08aac05e8bbaafebc2f39499a015333_product_card_v2_mobile_slider_639.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Art of Rally",
        "store": "Epic Games Store",
        "gamedescription": "art of rally is a stylized experience inspired by the golden era of rally from the creator of Absolute Drift. Race across the world through colorful and stylized environments in top-down view. Will you master the art of rally?",
        "gamelink": "https://store.epicgames.com/en-US/p/art-of-rally",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-artofrally-funselektor-ic1-400x400-071924490.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Melvor Idle",
        "store": "Epic Games Store",
        "gamedescription": "Inspired by RuneScape, Melvor Idle takes the core of what makes an adventure game so addictive and strips it down to its purest form! This is a feature-rich, idle/incremental game combining a distinctly familiar feel with a fresh gameplay experience.",
        "gamelink": "https://store.epicgames.com/p/melvor-idle-b6a9b3",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/1caf32ecc37545b0ad7a3fb6293fef28/melvor-idle-logo-1rgkg.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Legend of Keepers: Career of a Dungeon Manager",
        "store": "GoG",
        "gamedescription": "In this original mix of Dungeon Management and Roguelite, you will lead your career as a Dungeon Manager!",
        "gamelink": "https://www.gog.com/game/legend_of_keepers_career_of_a_dungeon_master",
        "gamecoverurl": "https://images.gog-statics.com/98d1a318c7a8e1538f7e65b5a39c3e1c3c0570cbc8a16065cf59707e594fd35b_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Destiny 2: Legacy Collection (2023)",
        "store": "Epic Games Store",
        "gamedescription": "Create your Guardian and embark on a cinematic story in an evolving universe filled with a variety of co-op missions and PvP modes.",
        "gamelink": "https://store.epicgames.com/p/destiny-2--legacy-collection-2023",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/428115def4ca4deea9d69c99c5a5a99e/EGS_Destiny2LegacyCollection2023_Bungie_Bundles_IC1_200x200-a2e6fd33ff997c29ae6b88c3d6a52d41?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Predecessor",
        "store": "Epic Games Store",
        "gamedescription": "Predecessor is the Paragon-fuelled, fast-paced action game that combines MOBA and FPS gameplay putting you at the heart of the fight with strategic choice, third person control and immersive action.",
        "gamelink": "https://store.epicgames.com/en-US/p/predecessor",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/85f0435aa88347d185dbcb147716609b/EGS_Predecessor_OmedaStudios_S1_2560x1440-9cc93232a68ae80a034f3fb291d788e5?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "GigaBash",
        "store": "Epic Games Store",
        "gamedescription": "Claim your place as king among Titans! GigaBash is a multiplayer arena brawler with gigantic film-inspired kaiju, larger than life heroes, earth-shattering special attacks and fully destructible environments.",
        "gamelink": "https://store.epicgames.com/en-US/p/gigabash",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/16fc138ed83846bc90d923fe0d87ad72/EGS_GigaBash_PassionRepublicGames_S1_2560x1440-f0d657610403750a33b2ed4a372d21ec?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Mighty Fight Federation",
        "store": "Epic Games Store",
        "gamedescription": "Slam opponents into walls, launch them into the air & follow up with fast, high-flying attacks in this arena fighter. Choose from 14 characters with unique play styles. Face off in 1v1 matches, team up with other fighters or embrace the chaos of free-for-all.",
        "gamelink": "https://store.epicgames.com/p/mighty-fight-federation",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/651d07f1eee8419e80eb2938f8f44cf0/EGS_MightyFightFederation_KomiGamesInc_S1_2560x1440-36750efe9ee51b49db0e85d4a577e4ff_2560x1440-36750efe9ee51b49db0e85d4a577e4ff?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Jitsu Squad",
        "store": "Epic Games Store",
        "gamedescription": "Jitsu Squad is a Marvel vs Capcom inspired 4 player co-op TAG TEAM beat ‘em up with epic character transformations and fast paced combat.",
        "gamelink": "https://store.epicgames.com/p/jitsu-squad-af3f2f",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/3d32732efa1840208f6488d243407eff/jitsu-squad-offer-5qemb.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Assassin's Creed Syndicate Standard Edition",
        "store": "uPlay",
        "gamedescription": "London, 1868. The Industrial Revolution unleashes an incredible age of invention, transforming the lives of millions with technologies once thought impossible. Opportunities created during this time period have people rushing to London to engage in this new world. A world no longer controlled by kings, emperors, politicians or religion, but by a new common denominator: money.",
        "gamelink": "https://register.ubisoft.com/acsyndicate/",
        "gamecoverurl": "https://cdn.cloudflare.steamstatic.com/steam/apps/368500/header.jpg?t=1692034785",
        "freeuntil": "expired"
    },
    {
        "gamename": "Deliver Us Mars",
        "store": "Epic Games Store",
        "gamedescription": "Deliver Us Mars is an atmospheric sci-fi adventure taking you on a suspense-fuelled, high-stakes mission to recover the ARK colony ships stolen by the mysterious Outward.",
        "gamelink": "https://store.epicgames.com/p/deliver-us-mars",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-deliverusmars-keokeninteractive-ic1-400x400-99d93cbc7dd4.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Styx: Shards of Darkness",
        "store": "GoG",
        "gamedescription": "Hired for a critical mission, explore and master huge open environments as Styx. Assassinate or sneak past enemies – Humans, Elves and Dwarves – but also much more fearsome, colossal creatures.",
        "gamelink": "https://www.gog.com/game/styx_shards_of_darkness",
        "gamecoverurl": "https://images.gog-statics.com/11b4528bbb6454703c0a0ac95e711acce476f0b98cccd71d1fa744d9a4c671f3_product_card_v2_mobile_slider_1710.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Half-Life",
        "store": "Steam",
        "gamedescription": "Named Game of the Year by over 50 publications, Valve's debut title blends action and adventure with award-winning technology to create a frighteningly realistic world where players must think to survive. Also includes an exciting multiplayer mode that allows you to play against friends and enemies.",
        "gamelink": "https://store.steampowered.com/app/70/HalfLife/",
        "gamecoverurl": "https://cdn.cloudflare.steamstatic.com/steam/apps/70/header.jpg?t=1700269108",
        "freeuntil": "expired"
    },
    {
        "gamename": "Surviving the Aftermath",
        "store": "Epic Games Store",
        "gamedescription": "Survive and thrive in a post-apocalyptic future — resources are scarce, but opportunity calls! Build the ultimate disaster proof colony, protect your colonists, and restore civilization to a devastated world.",
        "gamelink": "https://store.epicgames.com/p/surviving-the-aftermath",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/95b4d5a753d042678f775d5e1eb5ab25/EGS_SurvivingtheAftermath_IceflakeStudiosOy_S1_2560x1440-61c69731778b92a32a9dbfd7c1a35d72?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Earthlock",
        "store": "Epic Games Store",
        "gamedescription": "Earthlock is an indie developed, adventure RPG inspired by the classic 3D RPGs of the late 90's, with a fresh take on turn based combat and character progression.",
        "gamelink": "https://store.epicgames.com/p/earthlock-e7d979",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/3fa7dc9ed5df483cb4cba9a1e7d7497c/earthlock-15ff1.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Golden Light",
        "store": "Epic Games Store",
        "gamedescription": "Eat weapons, be scared and question your own mind in this Survival-Horror FPS Prop Hunt Roguelike about meat. Walls are watching you!",
        "gamelink": "https://store.epicgames.com/en-US/p/golden-light-f543b3",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/d3aec59bfafa4dd392d5c9b01abbaefd/golden-light-logo-1f045.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Turnip Boy Commits Tax Evasion",
        "store": "Epic Games Store",
        "gamedescription": "Play as an adorable yet trouble-making turnip. Avoid paying taxes, solve plantastic puzzles, harvest crops and battle massive beasts all in a journey to tear down a corrupt vegetable government!",
        "gamelink": "https://store.epicgames.com/en-US/p/turnip-boy-commits-tax-evasion-fb2084",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/9456221af5d04968ba41426fdb2c4557/turnip-boy-commits-tax-evasion-offer-1wsay.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Blacksad Under The Skin",
        "store": "GoG",
        "gamedescription": "Cryptark is a 2D sci-fi shooter where you take on the role of a heavily armed privateer seeking to earn government contracts by boarding deadly alien space-hulks. It's up to you to strategize a plan of attack, pick an equipment loadout, and destroy the central core to be victorious.",
        "gamelink": "https://www.gog.com/en/game/blacksad_under_the_skin",
        "gamecoverurl": "https://images.gog-statics.com/5cf6e25ecd065fce603ab03192eb1e30170b3b0257e4b596601a839e49391725_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Evil Within 2",
        "store": "Epic Games Store",
        "gamedescription": "Cryptark is a 2D sci-fi shooter where you take on the role of a heavily armed privateer seeking to earn government contracts by boarding deadly alien space-hulks. It's up to you to strategize a plan of attack, pick an equipment loadout, and destroy the central core to be victorious.",
        "gamelink": "https://store.epicgames.com/en-US/p/the-evil-within-2",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-theevilwithin2-tangogameworks-ic1-200x200-11a853335c6c.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Tandem: A Tale of Shadows",
        "store": "Epic Games Store",
        "gamedescription": "Tandem : A Tale of Shadows redefines the puzzle platformer genre with a unique gameplay and exceptional aesthetics. Help Emma and the teddy bear Fenton solve the mystery of the disappearance of the famous magician Thomas Kane.",
        "gamelink": "https://store.epicgames.com/en-US/p/tandem-a-tale-of-shadows-c3f55e",
        "gamecoverurl": "https://cdn1.epicgames.com/spt/50de8b6a-efdb-4b91-897d-1453e33f9c42/tandem-a-tale-of-shadows-logo-53754b5a.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Cryptark",
        "store": "Steam",
        "gamedescription": "Cryptark is a 2D sci-fi shooter where you take on the role of a heavily armed privateer seeking to earn government contracts by boarding deadly alien space-hulks. It's up to you to strategize a plan of attack, pick an equipment loadout, and destroy the central core to be victorious.",
        "gamelink": "https://store.steampowered.com/app/344740/CRYPTARK/",
        "gamecoverurl": "https://cdn.cloudflare.steamstatic.com/steam/apps/344740/header.jpg?t=1697733178",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Evil Within",
        "store": "Epic Games Store",
        "gamedescription": "Developed by Tango Gameworks and directed by Resident Evil series creator Shinji Mikami, The Evil Within embodies pure survival horror. As Detective Sebastian Castellanos, fight for survival with limited resources in a twisted world of nightmares.",
        "gamelink": "https://store.epicgames.com/en-US/p/the-evil-within",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-theevilwithin-tangogameworks-ic1-200x200-aa22fbef7649.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Eternal Threads",
        "store": "Epic Games Store",
        "gamedescription": "Eternal Threads is a single-player, first-person story-driven puzzle game of time manipulation, choice and consequence.",
        "gamelink": "https://store.epicgames.com/p/eternal-threads-197169",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/08813e055af64315b8a55b2e521f4e04/eternal-threads-logo-15w59.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Q.U.B.E Ultimate Bundle",
        "store": "Epic Games Store",
        "gamedescription": "Q.U.B.E. 10th Anniversary is the ultimate celebration of the decade-old original, bringing refined visuals, completely redesigned and revised gameplay sections, and a whole new chapter to the award-winning, brain-twisting first-person puzzler",
        "gamelink": "https://store.epicgames.com/en-US/p/q-u-b-e-2",
        "gamecoverurl": "https://cdn2.unrealengine.com/egsbundle-landscape-v2-2560x1440-8c65572ef88b.jpg?h=720&quality=medium&resize=1&w=1280",
        "freeuntil": "expired"
    },
    {
        "gamename": "Godlike Burger",
        "store": "Epic Games Store",
        "gamedescription": "In Godlike Burger you run the craziest restaurant of the galaxy! Stun, poison and kill customers in many devious ways… and turn them into burger meat! And don’t worry: Alien customers will keep coming if you are smart about it, because cannibalism is just too damn tasty.",
        "gamelink": "https://store.epicgames.com/en-US/p/godlike-burger-4150a0",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/f42598038b9343e58d27e0a8c0b831b6/godlike-burger-logo-13ccb.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Soulstice",
        "store": "Epic Games Store",
        "gamedescription": "Explore a dark world brimming with hidden mysteries, master a diverse combat system, and inhabit the dual forces of two sisters in a coming-of-age dark fantasy story with fast-paced action, vicious enemies, and spectacular boss fights.",
        "gamelink": "https://store.epicgames.com/en-US/p/soulstice",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-soulstice-replygamestudios-ic1-128x128-8eba5aafa373.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Model Builder",
        "store": "Epic Games Store",
        "gamedescription": "Join the latest trend in simulation games, bring out your creativity and make awesome looking models. Assemble, customize and display your creations, whether you’re a veteran or a novice. Get building and get creative!",
        "gamelink": "https://store.epicgames.com/p/model-builder-f9b456",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/7472ec268e344e77a85e95976c0ffc6d/model-builder-logo-pyfta.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Forest Quartet",
        "store": "Epic Games Store",
        "gamedescription": "The Forest Quartet is a 3D narrative puzzler about a gone, but not forgotten, lead singer. Play her spirit and travel through 3 acts unique to the members of her band for a final farewell concert. Face their emotions, solve puzzles and fight the corruption plaguing their soul",
        "gamelink": "https://store.epicgames.com/p/forest-quartet-5d03e3",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/5f711e24b6534e32981f910a38e20756/the-forest-quartet-ncqa1.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Out of Line",
        "store": "Epic Games Store",
        "gamedescription": "A unique adventure game filled with beautiful puzzles all hand-drawn in a unique 2D style . Out of Line follows the adventures of San in a quest to escape the Factory that was once home. Dive into a story set in a mysterious world that is revealed through multiple chapters.",
        "gamelink": "https://store.epicgames.com/p/out-of-line-209cbb",
        "gamecoverurl": "https://cdn1.epicgames.com/spt/8b4ecd70-a418-480e-b59c-e7f94f05d449/out-of-line-offer-4a5df3c7.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Tint n Ink Paintball",
        "store": "Steam",
        "gamedescription": "The definitive paintball game that you have been looking for over a decade. A solution for paintball teams to practice and compete on the most recent layouts with the best guns on the market. Join now to battle it out on Tint n Ink!",
        "gamelink": "https://store.steampowered.com/app/1997630/Tint_n_Ink_Paintball/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/1997630/header.jpg?t=1692312619",
        "freeuntil": "expired"
    },
    {
        "gamename": "WarBots",
        "store": "Steam",
        "gamedescription": "This is a platformer, 2D MOBA game with shooting robots.An 5v5 PvP arena where the goal is to destroy the enemy team's core. You can customize your equipment, which you can acquire by reaching higher levels.While you're dead, you control a small but deadly drone.",
        "gamelink": "https://store.steampowered.com/app/1892900/WarBots/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/1892900/header.jpg?t=1683618770",
        "freeuntil": "expired"
    },
    {
        "gamename": "Tachyonest",
        "store": "Steam",
        "gamedescription": "Engage in a fast-paced, third-person spaceship shooter, where strategic combat meets explosive action in an interstellar battlefield.",
        "gamelink": "https://store.steampowered.com/app/2428140/Tachyonest/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/2428140/header.jpg?t=1690923600",
        "freeuntil": "expired"
    },
    {
        "gamename": "We Were Here Expeditions: The FriendShip",
        "store": "Epic Games Store and Steam",
        "gamedescription": "Partner up with your friend online and test your friendship by solving puzzles together in an abandoned amusement park. Work together to overcome brain-teasing challenges in this standalone We Were Here experience.",
        "gamelink": "https://store.epicgames.com/en-US/p/we-were-here-expeditions-the-friendship-1e8fb2",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/a1107965665f4abbb8b5526fa5322abc/we-were-here-friendship-logo-196fs.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Crumble",
        "store": "Fanatical",
        "gamedescription": "Crumble is a physics platformer with a grappling tongue mechanic. Join the chaos of unstable platforms and unexpected destructions with this cute blue ball! Try to progress in exciting levels falling around you. Move like a Slime, jump like a Ball, swing like Spiderman!",
        "gamelink": "https://www.fanatical.com/en/game/crumble",
        "gamecoverurl": "https://fanatical.imgix.net/product/original/73307ef2-2591-4b3e-a117-26e6d9f782d8.jpeg?auto=compress,format&w=860&fit=crop&h=484",
        "freeuntil": "expired"
    },
    {
        "gamename": "911 Operator",
        "store": "Epic Games Store",
        "gamedescription": "A game about the difficult work of people that manage emergency services. Answer incoming calls and react properly - give first aid instructions, advise, dispatch the correct number of firemen/police/ambulances, or sometimes - just ignore the call. Play on ANY CITY in the world!",
        "gamelink": "https://store.epicgames.com/en-US/p/911-operator-585edd",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/c06cc46c27954f55974e9e7a4f3b3849/911-operator-logo-1q09n.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Requiem Avenging Angel",
        "store": "GoG",
        "gamedescription": "Requiem Avenging Angel is a 3D, first-person action game of biblical proportions set in a world under siege.",
        "gamelink": "https://www.gog.com/game/requiem_avenging_angel",
        "gamecoverurl": "https://images.gog-statics.com/b0eed2a11b70b6ee5dd84d72f672ef48ce219e02b429f852c066bfd0475218bd_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Spelldrifter",
        "store": "Epic Games Store",
        "gamedescription": "Spelldrifter combines the puzzle-like positional tactics of a turn-based RPG with the customizability and replayability of a collectible card game. The result: a hybrid, wherein players must juggle the resources at their disposal using both time and space.",
        "gamelink": "https://store.epicgames.com/en-US/p/spelldrifter",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-spelldrifter-freerangegames-ic1-400x400-51e85341386d.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Ghost Master",
        "store": "GoG",
        "gamedescription": "You become the Ghost Master in a unique witches brew of strategy, adventure, and ghoulish sim. Your sole purpose is to frighten the mortals right out of town.",
        "gamelink": "https://www.gog.com/en/game/ghost_master",
        "gamecoverurl": "https://images.gog-statics.com/fa3c17d11228532dedcc4ac371bc5fb80fb58be6b96e0544abf74b51aa6f47e8_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "King's Bounty: The Legend",
        "store": "GoG",
        "gamedescription": "Become a hero in a fantasy world of fearless knights, evil mages, wise kings and beautiful princesses. Explore the land, command armies and accomplish quests for your king to reap great rewards... Or suffer devastating defeats.",
        "gamelink": "https://www.gog.com/en/game/kings_bounty_the_legend",
        "gamecoverurl": "https://images.gog-statics.com/f43343ed27913f5749f5d517db907638bc0ff3461cffbb52ce8c1b910342e697_product_tile_256_2x.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Cave Story+",
        "store": "Epic Games Store",
        "gamedescription": "Arguably the most well-known indie game of all time, Cave Story+ features an original story brimming with personality, mystery and fast-paced fun. Run, jump, shoot, fly and explore your way through a massive adventure reminiscent of classic 8 and 16-bit games!",
        "gamelink": "https://store.epicgames.com/en-US/p/cave-story-plus",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-cavestory-nicalisincstudiopixel-ic1-400x400-633663636.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Hero of the Kingdom II",
        "store": "GoG",
        "gamedescription": "Sail to the farthest islands to save your sister and defeat pirates.",
        "gamelink": "https://www.gog.com/en/game/hero_of_the_kingdom_ii",
        "gamecoverurl": "https://images.gog-statics.com/94b1dc94228ccd153d62e8b0bbfa07669bfb0f0713db39a69bc3b8a2e60e2248_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Homeworld: Deserts of Kharak",
        "store": "Epic Games Store",
        "gamedescription": "A ground-based RTS prequel to the classic Homeworld games. Assemble your fleet and lead them to victory on the shifting sands of Kharak in this compelling strategy game for PC from Blackbird Interactive.",
        "gamelink": "https://store.epicgames.com/en-US/p/homeworld-deserts-of-kharak",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-homeworlddesertsofkharak-blackbirdinteractive-ic1-400x400-180d9360d4c9.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Dodo Peak",
        "store": "Epic Games Store",
        "gamedescription": "Big Brother has arrived and it is you. Investigate the lives of citizens to find those responsible for a series of terror attacks. But, be warned, the information you supply will have consequences…",
        "gamelink": "https://store.epicgames.com/en-US/p/dodo-peak-159276",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/f12a1b6884a4432dbd042e98a7eda29c/dodo-peak-logo-73tga.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Black Book",
        "store": "Epic Games Store",
        "gamedescription": "Big Brother has arrived and it is you. Investigate the lives of citizens to find those responsible for a series of terror attacks. But, be warned, the information you supply will have consequences…",
        "gamelink": "https://store.epicgames.com/en-US/p/black-book",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-blackbook-morteshka-ic1-400x200-f497425b4268.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Torment",
        "store": "Robot Cache",
        "gamedescription": "You are born falling from orbit, a new mind in a body once occupied by the Changing God, a being who has cheated death for millennia. If you survive, your journey through the Ninth World will only get stranger… and deadlier.",
        "gamelink": "https://store.robotcache.com/#/game/42/-",
        "gamecoverurl": "https://cdn.robotcache.com/game/42/42-14197-22-cb75da421b5a372bb870e013ef6104f4.webp",
        "freeuntil": "expired"
    },
    {
        "gamename": "Orwell Keeping an Eye on You",
        "store": "Epic Games Store",
        "gamedescription": "Big Brother has arrived and it is you. Investigate the lives of citizens to find those responsible for a series of terror attacks. But, be warned, the information you supply will have consequences…",
        "gamelink": "https://store.epicgames.com/en-US/p/orwell-keeping-an-eye-on-you",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-orwellkeepinganeyeonyou-osmoticstudios-ic1-400x400-42e80707a478.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Europa Universalis IV",
        "store": "Epic Games Store",
        "gamedescription": "Lead any nation from Renaissance to Revolution in a complex simulation of the early modern world. Master the art of war, diplomacy and trade to shape and change your nation’s history!",
        "gamelink": "https://store.epicgames.com/en-US/p/europa-universalis-iv",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-europauniversalisiv-paradoxdevelopmentstudioparadoxtinto-ic5-400x130-823bfb98515e.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Loop Hero",
        "store": "Epic Games Store",
        "gamedescription": "A stylish single-player FPS featuring a fluid stunt system, destructible voxel environments, loads of bullet time, a unique one-armed protagonist, and a dark electronic soundtrack.",
        "gamelink": "https://store.epicgames.com/en-US/p/loop-hero",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-thelichhasthrowntheworldintoatimelessloop-fourquarters-ic1-191x191-600f4cfefa70.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Bloons TD 6",
        "store": "Epic Games Store",
        "gamedescription": "A stylish single-player FPS featuring a fluid stunt system, destructible voxel environments, loads of bullet time, a unique one-armed protagonist, and a dark electronic soundtrack.",
        "gamelink": "https://store.epicgames.com/en-US/p/bloons-td-6-bf95a0",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/764b2d57552c436590f50318bd7587f9/bloons-td-6-logo-1vhmg.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },

    {
        "gamename": "Severed Steel",
        "store": "Epic Games Store",
        "gamedescription": "A stylish single-player FPS featuring a fluid stunt system, destructible voxel environments, loads of bullet time, a unique one-armed protagonist, and a dark electronic soundtrack.",
        "gamelink": "https://store.epicgames.com/en-US/p/severed-steel",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-severedsteel-greylockstudio-ic1-400x43-800197ae1885.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Homeworld Remastered Collection",
        "store": "Epic Games Store",
        "gamedescription": "Experience the epic space strategy games that redefined the RTS genre. Control your fleet and build an armada across more than 30 single-player missions. Choose unit types, fleet formations and flight tactics for each strategic situation.",
        "gamelink": "https://store.epicgames.com/en-US/p/homeworld-remastered-collection",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-homeworldremasteredcollection-gearboxsoftware-ic1-400x400-79f68ad62e48.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Dungeon of the ENDLESS",
        "store": "Steam",
        "gamedescription": "Dungeon of the ENDLESS is a Rogue-Like Dungeon-Defense game, in which the player and their team of heroes must protect the generator of their crashed ship while exploring an ever-expanding dungeon, all while facing waves of monsters and special events as they try to find their way out...",
        "gamelink": "https://store.steampowered.com/app/249050/Dungeon_of_the_ENDLESS/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/249050/header_alt_assets_2.jpg?t=1689949161",
        "freeuntil": "expired"
    },
    {
        "gamename": "Murder by Numbers",
        "store": "Epic Games Store",
        "gamedescription": "Solve Pixel Puzzles to find clues. Use those clues to interrogate witnesses. Work your way to the truth....and uncover the mystery of Murder by Numbers!",
        "gamelink": "https://store.epicgames.com/en-US/p/murder-by-numbers",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-murderbynumbers-mediatonic-ic1-400x400-7b3f8038a6d2.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Elder Scrolls Online",
        "store": "Epic Games Store",
        "gamedescription": "Join over 22 million players in the award-winning online multiplayer RPG and experience limitless adventure in a persistent Elder Scrolls world.",
        "gamelink": "https://store.epicgames.com/en-US/p/the-elder-scrolls-online",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-theelderscrollsonline-zenimaxonlinestudios-ic1-400x400-e689e1f985f9-414x141-1687876995615-413x141-d582fcbff7ec.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },

    {
        "gamename": "The Whisperer",
        "store": "GoG",
        "gamedescription": "Investigate an abandoned trading post, explore its surroundings and solve the terrible mystery it holds. The Whisperer (Le Murmureur) is a short story-driven point & click game set in Lower Canada at the beginning of the 19th century.",
        "gamelink": "https://www.gog.com/game/the_whisperer?pp=2863d7ae605104eeef364e3f164d3404e20f680c",
        "gamecoverurl": "https://images.gog-statics.com/b8053b1af011e1172be28c28ce61b635df33f848626acae691ceae057b4b3fe0_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Train Valley 2",
        "store": "Epic Games Store",
        "gamedescription": "Build even more complex railway networks than ever before! Building on the success of the original, you’ll get more of what you loved about the original and then some. With increasingly difficult and complex challenges from local industries, the Valley has never felt so vast!",
        "gamelink": "https://store.epicgames.com/en-US/p/train-valley-2-3606da",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/6d6a89f661f74d70bdc8be636c577056/train-valley-2-logo-1rxid.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Betrayer",
        "store": "GoG",
        "gamedescription": "Betrayer is a first person action adventure game that takes you to the New World at the turn of the 17th century.",
        "gamelink": "https://www.gog.com/en/game/betrayer",
        "gamecoverurl": "https://img.youtube.com/vi/2v6TkgkUdNE/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Crusader Kings II",
        "store": "GoG",
        "gamedescription": "The Dark Ages might be drawing to a close, but Europe is still in turmoil. Petty lords vie against beleaguered kings who struggle to assert control over their fragmented realms. The Pope calls for a Crusade to protect the Christians in the Holy Land even as he refuses to relinquish control over the investiture of bishops - and their riches.",
        "gamelink": "https://www.gog.com/game/crusader_kings_ii",
        "gamecoverurl": "https://images.gog-statics.com/213d787841d1878be7e0c3bbf8cfb953ceace118e19c2b6861bbfe09f9fab779_product_card_v2_mobile_slider_1710.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Wasteland 3",
        "store": "Robot Cache",
        "gamedescription": "In Wasteland 3 you take command of a squad of Desert Rangers, lawmen and women in a post-nuclear world, trying to rebuild society from the ashes. More than a century after the bombs fell, you’re fighting a losing battle to keep your beloved Arizona alive. Then the self-proclaimed Patriarch of Colorado radios, promising aid if you'll do a job he can only entrust to an outsider—rescue his land from the ambitions of his three bloodthirsty children.",
        "gamelink": "https://store.robotcache.com/#/game/403/-",
        "gamecoverurl": "https://cdn.robotcache.com/game/403/403-30104-12-1f4d736f928c5962c14294cb266eadbf.webp",
        "freeuntil": "expired"
    },
    {
        "gamename": "GRIME",
        "store": "Epic Games Store",
        "gamedescription": "GRIME is a fast, unforgiving Action-Adventure RPG in which you crush your foes with living weapons that mutate form and function, and then consume their remains with a black hole to strengthen your vessel as you break apart a world of anatomical intrigue.",
        "gamelink": "https://store.epicgames.com/en-US/p/grime",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-grime-cloverbite-ic1-400x400-c436bee0452f.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Beholder 2",
        "store": "GoG",
        "gamedescription": "Every citizen of our great State dreams of working at the Prime Ministry! You are lucky, intern, your dream has come true! While you are at the bottom of the career ladder, that is more than many of your fellow citizens will ever achieve!",
        "gamelink": "https://www.gog.com/game/beholder_2",
        "gamecoverurl": "https://images.gog-statics.com/94a00c057b921e9aa53137266ce013b4ed30a6a547a4acca6d152e047674c41a_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Dungeon Of Naheulbeuk: The Amulet Of Chaos",
        "store": "Epic Games Store",
        "gamedescription": "Step into the wacky heroic fantasy universe of the Dungeon of Naheulbeuk. Lead a team of unlikely and clumsy heroes in an epic and challenging tactical RPG. Live an adventure filled with humor, surprises and silly encounters.",
        "gamelink": "https://store.epicgames.com/en-US/p/the-dungeon-of-naheulbeuk",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-thedungeonofnaheulbeuktheamuletofchaos-artefactsstudio-ic5-400x192-344888592.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Ancient Enemy",
        "store": "GoG",
        "gamedescription": "Ancient Enemy is a strategic RPG card game in which you make interesting tactical decisions as you battle distorted enemies and overcome challenging puzzles.",
        "gamelink": "https://www.gog.com/game/ancient_enemy",
        "gamecoverurl": "https://images.gog-statics.com/06acaff9899656b510e3618d4986e56ec8835fcf9f4f9dd70b56724a34e413ca_product_card_v2_logo_480x285.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "theHunter: Call of the Wild",
        "store": "Epic Games Store",
        "gamedescription": "Experience an atmospheric hunting game like no other in this realistic and visually breathtaking open world. Immerse yourself in the atmospheric single player campaign, or share the ultimate hunting experience with friends.",
        "gamelink": "https://store.epicgames.com/p/thehunter-call-of-the-wild",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_theHunterCalloftheWild_ExpansiveWorlds_S1_2560x1440-69120885e0b3acfb87f34ac0bad68ec6?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Idle Champions of the Forgotten Realms",
        "store": "Epic Games Store",
        "gamedescription": "Idle Champions of the Forgotten Realms is a Dungeons & Dragons strategy management game uniting characters from throughout the D&D multiverse into a grand adventure.",
        "gamelink": "https://store.epicgames.com/p/idle-champions-of-the-forgotten-realms",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/7e508f543b05465abe3a935960eb70ac/IdleChampions_FreeGamesPromo_2560x14401_2560x1440-8ce28f11dab0dcafa37bfedfac54931d?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Nixxsz Castle",
        "store": "Steam",
        "gamedescription": "Nixxsz Castle is a portfolio with different types of games. Inspired by the gold standard of old games from previous generations, Nixxsz Castle brings new adventures with modern gameplay",
        "gamelink": "https://store.steampowered.com/app/2279380/Nixxsz_Castle/",
        "gamecoverurl": "https://cdn.cloudflare.steamstatic.com/steam/apps/2279380/header.jpg?t=1687208459",
        "freeuntil": "expired"
    },
    {
        "gamename": "Hell Division",
        "store": "GoG",
        "gamedescription": "Hell Division is a third-person shooter where you pilot lethal drones. Become a pilot of an armed military drone and plunge into adrenaline-fueled action set in a cyberpunk city divided between two ruthless corporations. The confrontation is inevitable, the question is – will you prevail?",
        "gamelink": "https://www.gog.com/en/game/hell_division",
        "gamecoverurl": "https://images.gog-statics.com/b570baa03deb21f2b9a2c4507af847e40aa8085b28028b75310ef09dfd4968eb_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Eschalon: Book II",
        "store": "GoG",
        "gamedescription": "The time has come to return to the realm of Eschalon! A new menace threatens Mistfell and the northern provinces, and evidence of your mysterious past come to light which will make you question everything you know about yourself. Don't miss this thrilling sequel to 2007's award-winning Eschalon: Book I!",
        "gamelink": "https://www.gog.com/game/eschalon_book_ii",
        "gamecoverurl": "https://images.gog-statics.com/8a1ca9e553a6c2485389b8085824a977d10e20fb496ae9a660fe127e1498014e_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Sigma Theory: Global Cold War",
        "store": "GoG",
        "gamedescription": "In the near future, a paradigm-shifting scientific discovery looms over the world, promising radical new technologies. The world’s superpowers realize they could have the power to destroy the global financial system, wipe out entire countries or even gain access to immortality.",
        "gamelink": "https://www.gog.com/game/sigma_theory_global_cold_war",
        "gamecoverurl": "https://images.gog-statics.com/7725455150dca4e458ce81a6a11f6703a48ad551de3f15e2e6264153ab14f41c_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Guacamelee! Super Turbo Championship Edition",
        "store": "Epic Games Store",
        "gamedescription": "Guacamelee! Super Turbo Championship Edition is a Metroidvania-style action-platformer set in a magical world inspired by traditional Mexican culture and folklore.",
        "gamelink": "https://store.epicgames.com/en-US/p/guacamelee-stce-7cdbc8",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/7041a1ca4ed543459b95d78887047bd9/guacamelee-stce-logo-xwrwn.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Guacamelee! 2",
        "store": "Epic Games Store",
        "gamedescription": "Head back to the Mexiverse in this long-awaited sequel to the smash hit Guacamelee! Uppercut your way to victory across stunning new hand-crafted levels. Featuring a dense and colorful world, new luchador moves, sassy new bosses, twice the enemies, and 300% more chickens!",
        "gamelink": "https://store.epicgames.com/en-US/p/guacamelee-2-ac4977",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/b05acd7d28d34f6d93a961b0e53ddf0b/guacamelee-2-logo-13bdi.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Sunblaze",
        "store": "GoG",
        "gamedescription": "Do you think you’ve got what it takes to be an actual superhero? Well, then you need to help Josie to beat the superhero training simulator, and to become the hero our world needs!",
        "gamelink": "https://www.gog.com/en/game/sunblaze",
        "gamecoverurl": "https://images.gog-statics.com/d630722a6d3898853dd92aebff924296d0800ea78b0550b8e035e6edd6112ba8_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Payday 2",
        "store": "Epic Games Store",
        "gamedescription": "PAYDAY 2 is an action-packed, four-player co-op shooter that once again lets gamers don the masks of the original PAYDAY crew - Dallas, Hoxton, Wolf and Chains - as they descend on Washington DC for an epic crime spree.",
        "gamelink": "https://store.epicgames.com/p/payday-2-c66369",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/14eb3477a6084940b49de5aa73c60f98/mammoth-logo-i1ks4.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Amnesia: A Machine For Pigs",
        "store": "GoG",
        "gamedescription": "The Swine are Rising! In 2010 Frictional Games terrified the world with the cult horror Amnesia: A Dark Descent. Now they bring you a new nightmare. Created in collaboration with The Chinese Room, the studio behind Dear Esther, Amnesia: A Machine for Pigs is an intense and terrifying journey into the heart of darkness that lurks within us all.",
        "gamelink": "https://www.gog.com/en/game/amnesia_a_machine_for_pigs",
        "gamecoverurl": "https://images.gog-statics.com/9422407b49c31bc8791d3858667bd6d8e4693b49485f37631f7acc9fc4d124e6_product_card_v2_logo_480x285.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Field of Glory II",
        "store": "Steam",
        "gamedescription": "Field of Glory II is a turn-based tactical game set during the Rise of Rome from 280 BC to 25 BC.",
        "gamelink": "https://store.steampowered.com/app/660160/Field_of_Glory_II/",
        "gamecoverurl": "https://cdn.cloudflare.steamstatic.com/steam/apps/660160/header.jpg?t=1682021256",
        "freeuntil": "expired"
    },
    {
        "gamename": "Tell Me Why",
        "store": "Steam",
        "gamedescription": "Tell Me Why is the multi-award winning episodic adventure game from Dontnod Entertainment in which twins use their supernatural bond to discover the truth of their troubled past.",
        "gamelink": "https://store.steampowered.com/app/1180660/Tell_Me_Why/",
        "gamecoverurl": "https://cdn.cloudflare.steamstatic.com/steam/apps/1180660/header.jpg?t=1684782496",
        "freeuntil": "expired"
    },
    {
        "gamename": "Midnight Ghost Hunt",
        "store": "Epic Games Store",
        "gamedescription": "A chaotic multiplayer hide-and-seek game. Possess seemingly harmless objects as Ghosts or chase them down as Hunters before the clock strikes midnight!",
        "gamelink": "https://store.epicgames.com/en-US/p/midnight-ghost-hunt",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-midnightghosthunt-vaultedskygames-ic1-400x400-62327163e788.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Hue",
        "store": "Steam",
        "gamedescription": "Hue is a vibrant, award-winning puzzle-adventure, where you alter the world by changing its background colour.",
        "gamelink": "https://store.steampowered.com/app/383270/Hue/",
        "gamecoverurl": "https://cdn.cloudflare.steamstatic.com/steam/apps/383270/header.jpg?t=1684854422",
        "freeuntil": "expired"
    },
    {
        "gamename": "Warhammer 40,000: Gladius - Relics of War",
        "store": "Steam",
        "gamedescription": "Warhammer 40,000: Gladius – Relics of War brings you to a world of terror and violence. Four factions will engage in a brutal war for dominance over the planet’s resources. In the first turn-based 4X strategy game set in Warhammer 40,000 you will lead one of four unique factions",
        "gamelink": "https://store.steampowered.com/app/489630/Warhammer_40000_Gladius__Relics_of_War/",
        "gamecoverurl": "https://cdn.cloudflare.steamstatic.com/steam/apps/489630/header_alt_assets_0.jpg?t=1685104508",
        "freeuntil": "expired"
    },
    {
        "gamename": "Fallout: New Vegas - Ultimate Edition",
        "store": "Epic Games Store",
        "gamedescription": "Feel the Heat in New Vegas! Not even nuclear fallout could slow the hustle of Sin City. Explore the vast expanses of the desert wastelands and see the Great Southwest as could only be imagined in Fallout.",
        "gamelink": "https://store.epicgames.com/en-US/p/fallout-new-vegas--ultimate-edition",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-falloutnewvegasultimateedition-obsidianentertainment-editions-g1a-00-1920x1080-fc9249ce3b15.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Fiendish Freddy's Big Top o' Fun",
        "store": "GoG",
        "gamedescription": "All right, boys and girls. You're in for quite a treat with Fiendish Freddy's Big Top O' Fun. We have not one, not two, but six , count 'em six, zany games of circus excitement. Yes, friends, a real sports-o-rama. Soon you'll be the talk of your neighborhood as you compete in the death-defying events in this circus extravaganza. And a word to the wise—watch out for that rascal of a saboteur, that do-er of dastardly deeds, that vindictive victim of the rare form of dementia known as circusus interruptus—the one, the only, Fiendish Freddy.",
        "gamelink": "https://www.gog.com/game/fiendish_freddys_big_top_o_fun",
        "gamecoverurl": "https://images.gog-statics.com/911216f3be8c8db228b95af7bc281bb7c6dfa6a3ceb2cee0bf34af4503f8347f_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Death Stranding",
        "store": "Epic Games Store",
        "gamedescription": "From legendary game creator Hideo Kojima comes an all-new, genre-defying experience. Sam Bridges must brave a world utterly transformed by the Death Stranding. Carrying the disconnected remnants of our future in his hands, he embarks on a journey to reconnect the shattered world",
        "gamelink": "https://store.epicgames.com/en-US/p/death-stranding",
        "gamecoverurl": "https://cdn2.unrealengine.com/Diesel%2Fproductv2%2Fdeath-stranding%2Fhome%2FEGS_KojimaProductions_DeathStranding_G1A_00-1920x1080-73cbf48f73660b4ce8d98eb7fdff27d8f32d5742.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Metro: Last Light Redux",
        "store": "Steam",
        "gamedescription": "It is the year 2034. Beneath the ruins of post-apocalyptic Moscow, in the tunnels of the Metro, the remnants of mankind are besieged by deadly threats from outside... and within. Mutants stalk the catacombs beneath the desolate surface and hunt amidst the poisoned skies above. But rather than stand united, the station-cities of the Metro are locked in a struggle for the ultimate power, a doomsday device from the military vaults of D6. A civil war is stirring that could wipe humanity from the face of the earth forever., As Artyom, burdened by guilt but driven by hope, you hold the key to our survival – the last light in our darkest hour...",
        "gamelink": "https://store.epicgames.com/p/metro-last-light-redux",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/287390/header.jpg?t=1669390806",
        "freeuntil": "expired"
    },
    {
        "gamename": "Bloody Hell",
        "store": "GoG",
        "gamedescription": "Embark on a hellish adventure! A short but sweet game about an Angel on a Bloody Bullet-hell mission to kill Satan! Dash, Double Dash, Grapple & Blast your way through Hell to fulfill your Holy Mission. Will you be able to kill Satan? Or will you fail to master your abilities and DIE in this BLOODY HELL?",
        "gamelink": "https://www.gog.com/en/game/bloody_hell?pp=2863d7ae605104eeef364e3f164d3404e20f680c",
        "gamecoverurl": "https://images.gog-statics.com/5f8155ae0a7bd2fd6bd6d9accf95a376fe4e73e2a4e5d2b5a3e3c79a28e2e658_product_card_v2_logo_480x285.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Kao the Kangaroo",
        "store": "Epic Games Store",
        "gamedescription": "Embark on an epic journey, master magical gloves, explore lush environments and take Kao through his most grand tale yet!",
        "gamelink": "https://store.epicgames.com/en-US/p/kao-the-kangaroo-62abe3",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/4a2dcb55ecca496aaaf328f60263bb56/kao-the-kangaroo-logo-1bm5h.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Horizon Chase Turbo",
        "store": "Epic Games Store",
        "gamedescription": "Horizon Chase Turbo is a racing game inspired by the great hits of the 80's and 90's. It recreates the classic arcade gameplay with unbound speed limits of fun. Full throttle on and enjoy!",
        "gamelink": "https://store.epicgames.com/en-US/p/horizon-chase-turbo",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-horizonchaseturbo-aquiris-ic1-200x48-a80c1e9ba82b.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Against all Odds",
        "store": "Epic Games Store",
        "gamedescription": "Water's cut. Rent's due. Jobs are scarce. Time to get rich! Enter the Slaughter League and win against all the odds in a dizzying array of lethal multiplayer races. In this competitive, multiplayer platformer party game there can only be one winner, so kiss friendships goodbye!",
        "gamelink": "https://store.epicgames.com/en-US/p/against-all-odds-409105",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/a2f2a8a46ff648928b50bcc776bf9857/slaughter-league-logo-15sbc.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Under the Moon",
        "store": "GoG",
        "gamedescription": "Under the Moon is an indie adventure - riddle game. If you're a riddle enthusiast you will have lots of fun while solving riddles in game while experiencing the stylized graphics, ambient soundtracks (which are exclusive for the level that you're in) and mellow atmosphere.",
        "gamelink": "https://www.gog.com/game/under_the_moon",
        "gamecoverurl": "https://images.gog-statics.com/5f869210bfe54bcc3f35b759d439410470c50dcf63ef46bb7a8f5ef6c2556fa1_product_card_v2_logo_960x570.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Poker Club",
        "store": "Epic Games Store",
        "gamedescription": "Welcome to the most immersive poker simulation ever made. Live the life of a poker pro in a global Poker Tour, then join a Club and make a name for yourself in 200-player online tournaments.",
        "gamelink": "https://store.epicgames.com/en-US/p/poker-club-6e18ee",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/560813614bba464385b56f43524d17f0/poker-club-logo-ux7tl.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Breathedge",
        "store": "Epic Games Store",
        "gamedescription": "Survive in outer space! Together with your immortal chicken, discover the truth behind your sudden spaceship crash. Craft tools, pilot vehicles, and even control space stations to survive and explore the wreckage.",
        "gamelink": "https://store.epicgames.com/en-US/p/breathedge",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-breathedge-redruinssoftworks-ic1-400x81-830d158e3aa6.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Locked in Mind",
        "store": "Steam",
        "gamedescription": "In Locked in Mind, you are trapped in a virtual escape room full of puzzles. Use your wits to solve them at your own pace without the pressure of a time limit. Perfect for players of all skill levels. Start your adventure today!",
        "gamelink": "https://store.steampowered.com/app/2252820/Locked_In_Mind/",
        "gamecoverurl": "https://cdn.cloudflare.steamstatic.com/steam/apps/2252820/header.jpg?t=1682509492",
        "freeuntil": "expired"
    },
    {
        "gamename": "Beyond Blue",
        "store": "Epic Games Store",
        "gamedescription": "Beyond Blue is a single-player narrative adventure that takes you deep into our planet’s beating blue heart. Explore the awesome wonder and unbounded mystery that exists within the world’s ocean.",
        "gamelink": "https://store.epicgames.com/en-US/p/beyond-blue",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-beyondblue-elinemedia-ic1-400x70-129668352.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Never Alone (Kisima Ingitchuna)",
        "store": "Epic Games Store",
        "gamedescription": "Experience the epic journey of Nuna and Fox as they search for the source of an eternal blizzard that threatens the survival of everything they have ever known.",
        "gamelink": "https://store.epicgames.com/en-US/p/never-alone-kisima-ingitchuna",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/b13f6a1e5121469d8a825947b759339d/EGS_NeverAloneKisimaIngitchuna_UpperOneGamesELineMedia_S1_2560x1440-97a1d9c3e47e439ea05e900feaa4a892?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Desktop Dungeons",
        "store": "Steam",
        "gamedescription": "Each step into the unknown heals you and reveals new obstacles as you guide fresh heros to glorious retirement or ignominious death. Master tactical spells, appease ancient gods and get rich through taxidermy in this award-winning quick-play puzzle-roguelike.",
        "gamelink": "https://store.steampowered.com/app/226620/Desktop_Dungeons/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/226620/header.jpg?t=1681842667",
        "freeuntil": "expired"
    },
    {
        "gamename": "Second Extinction",
        "store": "Epic Games Store",
        "gamedescription": "Big map. Big dinosaurs. Big guns. Face an ever changing threat level with your friends. Second Extinction is an online co-op FPS with a bite.",
        "gamelink": "https://store.epicgames.com/en-US/p/second-extinction",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-secondextinction-systemicreaction-ic1-400x400-c3d060d94aec.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Mordhau",
        "store": "Epic Games Store",
        "gamedescription": "MORDHAU is a multiplayer medieval slasher. Create your mercenary and fight in brutal battles where you will experience fast paced combat, castle sieges, cavalry charges, and more.",
        "gamelink": "https://store.epicgames.com/en-US/p/mordhau",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-mordhau-triterniondoo-ic1-400x150-5bdf9c15c0eb.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Fire & Maneuver",
        "store": "Steam",
        "gamedescription": "From the team that brought you The Armchair Historian comes a new strategy game set in the Victorian Era. As muskets become rifles and infantry are replaced by Gatling guns, you must adapt to a rapidly changing battlefield. This is a new age, not of courage and chivalry, but of Blood and Iron.",
        "gamelink": "https://store.steampowered.com/app/1679290/Fire__Maneuver/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/1679290/header.jpg?t=1680123357",
        "freeuntil": "expired"
    },
    {
        "gamename": "Battlestar Galactica Deadlock",
        "store": "Steam",
        "gamedescription": "In Battlestar Galactica Deadlock you’ll take command of the Colonial Fleet in defense of the Twelve Colonies during the First Cylon War. Lead many different types of ships in this 3D tactical game. Every decision counts, your strategy will be vital – can you ensure mankind’s survival?",
        "gamelink": "https://store.steampowered.com/app/544610/Battlestar_Galactica_Deadlock/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/544610/header.jpg?t=1680286394",
        "freeuntil": "expired"
    },
    {
        "gamename": "shapez",
        "store": "Epic Games Store",
        "gamedescription": "shapez is a game about building factories to automate the creation and processing of increasingly complex shapes across an infinitely expanding map.",
        "gamelink": "https://store.epicgames.com/en-US/p/shapez-0929c9",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/6aac3071aeae452990a71aac0a1fbc30/shapez-logo-8pryd.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Dying Light Enhanced Edition",
        "store": "Epic Games Store",
        "gamedescription": "Dying Light: Enhanced Edition is all you need to start your adventure in the post-apocalyptic world ruled by zombies. Featuring an expansion with the buggy, your new ride, an additional game mode, two extra quarantine zones, and two bundles.",
        "gamelink": "https://store.epicgames.com/en-US/p/dying-light",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-dyinglightenhancededition-techland-ic1-200x200-8ee5c80454b2.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Deep Sky Derelicts",
        "store": "GoG",
        "gamedescription": "In a grim dystopian future, where mankind has scattered across the galaxy and the human society has split into two distinct classes, you are a poor stateless outcast forced to live off scraps from derelict alien stations and ships in the outer space, yet you dream of becoming a privileged citizen and living on the surface of a habitable planet, enjoying non-synthetic air, water and food. A fabled alien derelict ship somewhere within the Deep Sky sector of space is your voucher for a citizenship and a promise of cozy life on a hospitable planet.",
        "gamelink": "https://www.gog.com/game/deep_sky_derelicts",
        "gamecoverurl": "https://images.gog-statics.com/d1398f92a4bee441933d07c5cf5c22d5afae8f8c888edf38bea1f086832cb4ea_product_card_v2_logo_480x285.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Silent Age",
        "store": "Epic Games Store",
        "gamedescription": "Experience a post-apocalyptic world like never before in The Silent Age, a thrilling point-and-click adventure game. Use time travel to unravel the mystery behind humanity's disappearance and save the world from extinction. Start your journey now!",
        "gamelink": "https://store.epicgames.com/en-US/p/the-silent-age-eb6972",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/11e5900e5dea4d70a35239c81bcda19e/the-silent-age-logo-1onxt.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Tunche",
        "store": "Epic Games Store",
        "gamedescription": "Tunche, a being of great power, has gone mad and now evil creatures are taking over the jungle. Help Rumi and her friends uncover the truth behind Tunche's actions and restore peace in the Amazon in this charming roguelike action game!",
        "gamelink": "https://store.epicgames.com/en-US/p/tunche",
        "gamecoverurl": "https://cdn2.unrealengine.com/ee931547-cb29-4b1e-b6e6-29dd9be16b3a-400x207-b6e9237adf01.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Neurodeck: Psychological Deckbuilder",
        "store": "GoG",
        "gamedescription": "Made by Tavrox Games, Zellana and Roman Cabezos. Published by Goblinz Studio",
        "gamelink": "https://www.gog.com/game/neurodeck_psychological_deckbuilder",
        "gamecoverurl": "https://images.gog-statics.com/2ca901bea6f3003a3e19689d12a63724d23947a71e0187c30b19fae422c095b4_product_card_v2_logo_480x285.png",
        "freeuntil": "expired"
    },
    {
        "gamename": "Chess Ultra",
        "store": "Epic Games Store",
        "gamedescription": "Introducing Chess Ultra; the most breathtaking chess game ever made. Experience stunning 4K visuals, seamless online multiplayer, Grandmaster approved AI and full VR compatibility.",
        "gamelink": "https://store.epicgames.com/en-US/p/chess-ultra-91bba3",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/f8581083c427408285529920a8afbdac/chess-ultra-logo-10hsw.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "World of Warships — Starter Pack: Ishizuchi",
        "store": "Epic Games Store",
        "gamedescription": "Have you just started out on your way in World of Warships? Dip your toes in the water with a Premium battleship and a set of sweet bonuses.",
        "gamelink": "https://store.epicgames.com/en-US/p/world-of-warships--starter-pack-ishizuchi",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/84c76746bce94effb8e1047fabfd7eb7/EGS_WorldofWarshipsStarterPackIshizuchi_Wargaming_AddOn_IC1_184x184-7319a21e5635aa9327672272508ed92d?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Lorelai",
        "store": "GoG",
        "gamedescription": "Lorelai will never forget that day. The little she had, it was taken away. Her whole world disintegrated. She never really had a chance, but Lorelai refused to give up. She will fight. And not even death will stop her from getting it all back.",
        "gamelink": "https://www.gog.com/en/game/lorelai",
        "gamecoverurl": "https://img.youtube.com/vi/gd_5-Pr3WZ0/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Tiny Tina's Assault on Dragon Keep: A Wonderlands One-shot Adventure",
        "store": "Steam",
        "gamedescription": "Smash skeletons, defeat dragons, and battle giant golems in Tiny Tina's Assault on Dragon Keep: A Wonderlands One-Shot Adventure. Experience the acclaimed 2013 quest that started it all in this standalone campaign jam-packed with fantasy, fun, and mountains of magical loot!",
        "gamelink": "https://store.steampowered.com/app/1712840/Tiny_Tinas_Assault_on_Dragon_Keep_A_Wonderlands_Oneshot_Adventure/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/1712840/header.jpg?t=1677522819",
        "freeuntil": "expired"
    },
    {
        "gamename": "Call of the Sea",
        "store": "Epic Games Store",
        "gamedescription": "Call of the Sea is an otherworldly tale of mystery and love set in the 1930s South Pacific. Explore a lush island paradise, solve puzzles and unlock secrets in the hunt for your husband’s missing expedition.",
        "gamelink": "https://store.epicgames.com/en-US/p/call-of-the-sea",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-callofthesea-outoftheblue-s1-2560x1440-dfa0ddeed91c.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Black Desert",
        "store": "Steam",
        "gamedescription": "Played by over 20 million Adventurers - Black Desert Online is an open-world, action MMORPG. Experience intense, action-packed combat, battle massive world bosses, fight alongside friends to siege and conquer castles, and train in professions such as fishing, trading, crafting, cooking, and more!",
        "gamelink": "https://store.steampowered.com/app/582660/Black_Desert/",
        "gamecoverurl": "https://cdn.cloudflare.steamstatic.com/steam/apps/582660/header.jpg?t=1677957850",
        "freeuntil": "expired"
    },
    {
        "gamename": "Figment",
        "store": "Steam",
        "gamedescription": "Figment is an action-adventure game that invites you to explore a unique surreal universe filled with music, humor and multi layered narrative. Join Dusty and his ever-optimistic friend, Piper, on an adventure through the different sides of the mind seeking to restore the courage that's been lost.",
        "gamelink": "https://store.epicgames.com/en-US/p/figment",
        "gamecoverurl": "https://cdn.cloudflare.steamstatic.com/steam/apps/493540/header.jpg?t=1676400114",
        "freeuntil": "expired"
    },
    {
        "gamename": "Rise Of Industry",
        "store": "Epic Games Store",
        "gamedescription": "Put your entrepreneurial skills to the test as you create & optimise intricate production lines whilst keeping an eye on the all-important bottom line. As an early 20th Century industrialist, grow your empire & adapt to unexpected events that could lead to boom…or bust.",
        "gamelink": "https://store.epicgames.com/en-US/p/rise-of-industry-0af838",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/a6aeec29591b4b56b4383b4d2d7d0e1e/rise-of-industry-logo-1z0kq.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Duskers",
        "store": "Epic Games Store",
        "gamedescription": "Pilot drones into derelict spaceships to find the means to survive and piece together how the universe became a giant graveyard.",
        "gamelink": "https://store.epicgames.com/en-US/p/duskers-672fdc",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/63398ad79bbc48bb8fda573695ad0873/duskers-logo-1yorm.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Warpips",
        "store": "Epic Games Store",
        "gamedescription": "Warpips is the ultimate quick to learn but amazingly deep tug-of-war strategy game. Deploy the right composition of soldiers, tanks, helicopters and planes in this tight, streamlined strategy-focused war game. Compose the best army, research the right tech, overwhelm your enemy!",
        "gamelink": "https://store.epicgames.com/p/warpips-57e2c4",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/581b8af82db34e5c98f5dae3ed5b9904/warpips-logo-tbmjs.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Alien Breed Trilogy",
        "store": "GoG",
        "gamedescription": "Experience the ultimate in science-fiction action with the Alien Breed™ Trilogy: three arcade-shooter titles in one fantastic collection, together for the first time. The Alien Breed™ Trilogy resurrects a much revered franchise with an epic narrative, swarms of intelligent alien enemies, high-impact weaponry and rich environments, all implemented with superb technology in a state-of-the-art gaming experience, developed using Epic Games' Unreal® Engine 3. The thrilling shooter trilogy, all in one collection: Alien Breed™: Impact is the first explosive chapter in the Alien Breed™ Trilogy, and is an action-packed mix of arcade-shooter, survival-horror and tactical weapons upgrades and customization. The player takes control of Theodore J. Conrad, the heroic engineer on the spaceship Leopold, which has collided with a mysterious ghost ship after dropping out of hyperspace. Conrad must take up arms against the vicious alien invaders that have overwhelmed his ship and fight to save his life. Alien Breed™ 2: Assault is the second chapter in the Alien Breed™ Trilogy. Continue your fight for survival as Conrad, across the fatally damaged space vessels, armed with destructive new weaponry, against the ongoing ruthless alien assaults, including thrilling new set pieces. Take up the challenge and try to stay alive in the all new Survivor modes. Alien Breed™ 3: Descent is the final explosive chapter in the Alien Breed™ Trilogy. Once again take control of the hero, Conrad, the ship’s Chief Engineer, in his last stand against the savage alien horde. Equipped with powerful new weapons, survive the action-packed third-person action sections, as the horrifying truth surrounding the existence of the Breed is finally uncovered…",
        "gamelink": "https://www.gog.com/game/alien_breed_trilogy",
        "gamecoverurl": "https://images.gog-statics.com/a71fb1de841bcba7222dc59bd3efb06bc854aca099bdda8c000398e05aa96cb4_product_card_v2_mobile_slider_639.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Recipe For Disaster",
        "store": "Epic Games Store",
        "gamedescription": "Recipe for Disaster is a management sim that captures the fast-paced, drama-filled environment of a professional kitchen and dining room. Build your dream restaurant, create recipes, design menus and manage your staff, all while contending with disastrous situations!",
        "gamelink": "https://store.epicgames.com/p/recipe-for-disaster-83726f",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/83ddef361d0c45c2bef72e07c2a5f567/recipe-for-disaster-logo-150ju.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Dishonored®: Death of the Outsider™",
        "store": "Epic Games Store",
        "gamedescription": "Take on the role of Billie Lurk as she reunites with her old mentor, Daud, to undertake the greatest assassination ever conceived: killing the Outsider, a god-like figure whom they both see as instrumental to some of the Empire's most dishonorable moments.",
        "gamelink": "https://store.epicgames.com/p/dishonored-death-of-the-outsider",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/87c84bc5f43d4fe69ad8f3ccde0594b0/EGS_DishonoredDeathoftheOutsider_ArkaneStudios_S1_2560x1440-7384b1e04742564e240e5e92722f82d6?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "City of Gangsters",
        "store": "Epic Games Store",
        "gamedescription": "In this management tycoon game, you’ll start a criminal operation from nothing and grow it into a well-oiled money machine! Build speakeasies and illegal distilleries. Leverage favors, chase down debtors, and bribe the police to look the other way.",
        "gamelink": "https://store.epicgames.com/p/city-of-gangsters-6c2974",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/148df842b2b14082be6b004f59a2765b/city-of-gangsters-offer-16sbj.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Hell is Others",
        "store": "Epic Games Store",
        "gamedescription": "Hell is Others is an extraction horror PVPVE top-down shooter. Outside the safety of your apartment lies the noir hellscape of Century City. A city of endless night that straddles the line between reality and insanity. Explore, hunt and loot in a place where blood is currency.",
        "gamelink": "https://store.epicgames.com/p/hell-is-others-789262",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/0de2d0d8870f457eaf7c6bf8c64f7f9e/hell-is-others-86xt2.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Adios",
        "store": "Epic Games Store",
        "gamedescription": "Adios is a cinematic first-person game about sticking to a complicated decision.",
        "gamelink": "https://store.epicgames.com/p/adios-b378b4",
        "gamecoverurl": "https://cdn1.epicgames.com/spt/e8e605d4-bd25-48d7-b99c-c8df348c9d81/adios-offer-41329995.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Haven Park",
        "store": "GoG",
        "gamedescription": "I'm getting older, my dear, and I'm not able to take care of the park any longer... Will you help me bring the life back into this place? Be Flint, who is doing his very best to keep his grandma's park up and running and make it a place for the campers to enjoy",
        "gamelink": "https://www.gog.com/game/haven_park",
        "gamecoverurl": "https://img.youtube.com/vi/t0xvvZ3H1o4/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Epistory - Typing Chronicles",
        "store": "Epic Games Store",
        "gamedescription": "Epistory immerses you in an atmospheric game where you play a girl riding a giant fox who fights an insectile corruption from an origami world. As you progress and explore this world, the story literally unfolds and the mysteries of the magic power of the words are revealed.",
        "gamelink": "https://store.epicgames.com/p/epistory-typing-chronicles-445794",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/2fe270709f944fc398851454cf476f95/epistory-typing-adventure-1t9zx.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Gamedec - Definitive Edition",
        "store": "Epic Games Store",
        "gamedescription": "Gamedec is a single-player cyberpunk isometric RPG. You are a game detective, who solves crimes inside virtual worlds. Use your wits to gather info from your witnesses and suspects, getting to the bottom of deceptive schemes. You are the sum of your choices.",
        "gamelink": "https://store.epicgames.com/p/gamedec",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/b8abdf9f9bc64ed6b4128a71fd47607f/EGS_GamedecDefinitiveEdition_AnsharStudios_S1_2560x1440-3d18c3f9ffe2ba4ca88305479f99d1be?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Divine Knockout (DKO)",
        "store": "Epic Games Store",
        "gamedescription": "KO the gods in the world’s only 3rd-person platform fighter! Become a small-but-mighty god of myth, brawl in all three dimensions, and knock your friends off the map to reign supreme.",
        "gamelink": "https://store.epicgames.com/p/divine-knockout--standard",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/1200aeb2f87c48349594d96554e2833d/DKO-LandscapeImage-2560x1440-Logo_2560x1440-1586b0605f4c36caa684de0433f71f01?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "First Class Trouble",
        "store": "Epic Games Store",
        "gamedescription": "First Class Trouble is a game where players work together and against each other to survive a disaster. The goal is to shut down a deadly A.I. Some players are impostors, secretly playing as human-looking killer robots intent on betraying the other players.",
        "gamelink": "https://store.epicgames.com/p/first-class-trouble",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/egs-firstclasstrouble-invisiblewalls-s1-2560x1440-061767b6ed3f_2560x1440-5949bbfd7f9dd488810526905e93a0f1?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Kerbal Space Program",
        "store": "Epic Games Store",
        "gamedescription": "In Kerbal Space Program, take charge of the space program for the alien race known as the Kerbals. You have access to an array of parts to assemble fully-functional spacecraft that flies (or doesn’t) based on realistic aerodynamic and orbital physics.",
        "gamelink": "https://store.epicgames.com/p/kerbal-space-program",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/ca0530b485754032be50cb6f27be0c2c/EGS_KerbalSpaceProgram_Squad_S1_2560x1440-380681e19358cb3d1e1162ce755e5e01?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Shadow Tactics - Aiko's Choice",
        "store": "Epic Games Store",
        "gamedescription": "Aiko's Choice is a brand-new standalone addon to the beloved stealth tactics game Shadow Tactics. Set in Japan around the Edo period, you take control of kunoichi adept Aiko and her deadly assassin friends to hunt down the ghosts of her past.",
        "gamelink": "https://store.epicgames.com/p/shadow-tactics-aikos-choice-5678c1",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/24f4bf69387246819ea43509effb6ba4/shadow-tactics--aikos-choice-offer-phujx.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Daymare: 1998",
        "store": "GoG",
        "gamedescription": "DAYMARE: 1998 is a third-person survival horror with hardcore survival mechanics and hard to kill enemies. It requires a strategical approach to combat and puzzles and offers a multi-character point of view on the story, revealing a deep and obscure lore. The scene is set with a secret research.",
        "gamelink": "https://www.gog.com/game/daymare_1998",
        "gamecoverurl": "https://img.youtube.com/vi/mHHVdBW29sM/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Dishonored - Definitive Edition",
        "store": "Epic Games Store",
        "gamedescription": "Experience the definitive Dishonored collection. This complete compilation includes Dishonored as well as all of its additional content - Dunwall City Trials, The Knife of Dunwall, The Brigmore Witches and Void Walker’s Arsenal.",
        "gamelink": "https://store.epicgames.com/p/dishonored-definitive-edition",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_DishonoredDefinitiveEdition_ArkaneStudios_S3_2560x1440-172cba030b0261550b46b6c1224330bf?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Eximius: Seize the Frontline",
        "store": "Epic Games Store",
        "gamedescription": "EXIMIUS is a FPS/RTS hybrid that focuses on squad-based combat. The game features an intense 5v5 multiplayer experience with each team comprising of 4 Squad Officers and one Commander.",
        "gamelink": "https://store.epicgames.com/p/eximius-seize-the-frontline",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/1c943de0163f4f0982f34dc0fc37dce9/EGS_EximiusSeizetheFrontline_AmmoboxStudios_S11_2560x1440-afd78f58327ae2bf5ae3e6f38ea0b6b3?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Mortal Shell",
        "store": "Epic Games Store",
        "gamedescription": "Mortal Shell is a deep action-RPG that tests your sanity and resilience in a shattered world. Your adversaries spare no mercy, with survival demanding superior awareness and precision. Possess lost warriors, discover hidden sanctums, and face formidable foes.",
        "gamelink": "https://store.epicgames.com/p/mortal-shell",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/066a34b0-e1dc-4eee-87f9-42b95d5238c6_2560x1440-60b72b7b1479ebafc188502d441c3ee5?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Worms Revolution Gold Edition",
        "store": "GoG",
        "gamedescription": "Includes: Worms Revolution, Worms Revolution - Customization Pack, Worms Revolution - Mars Pack, Worms Revolution: Funfair DLC, Worms Revolution: Medieval Tales DLC Worms™ Revolution is another entry in the classic turn-based strategy series to come to the PC.",
        "gamelink": "https://www.gog.com/game/Worms_Revolution",
        "gamecoverurl": "https://img.youtube.com/vi/KMtNQ0UVmjM/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "DEATH STRANDING DIRECTOR'S CUT (Only for 24H!)",
        "store": "Epic Games Store",
        "gamedescription": "From legendary game creator Hideo Kojima comes a genre-defying experience, now expanded in this definitive DIRECTOR’S CUT. As Sam Bridges, your mission is to deliver hope to humanity by connecting the last survivors of a decimated America.",
        "gamelink": "https://store.epicgames.com/p/death-stranding",
        "gamecoverurl": "https://cdn1.epicgames.com/f4a904fcef2447439c35c4e6457f3027/offer/DS_wide-2560x1440-c3d7bbf8ee36dd025610088381a5235a.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Greak: Memories of Azur",
        "store": "GoG",
        "gamedescription": "Greak: Memories of Azur is a side scrolling single-player game with hand-drawn animations. You will take the role of three siblings: Greak, Adara and Raydel to guide them through the lands of Azur. Alternate control between them and use their unique abilities to escape from the Urlag invasion.",
        "gamelink": "https://www.gog.com/en/game/greak_memories_of_azur",
        "gamecoverurl": "https://img.youtube.com/vi/JYOMkXRXBCg/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Encased (Only for 24H!)",
        "store": "Epic Games Store",
        "gamedescription": "A tactical sci-fi RPG set in an alt-1970's, where an enormous and inexplicable artifact –the Dome– is discovered in a remote desert. Fight enemies, explore the anomalous wasteland, level up your character & join one of the forces in the ruined world.",
        "gamelink": "https://store.epicgames.com/p/encased",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/48ee23bbc7c34ac09604253e74d17b49/EGS_Encased_DarkCrystalGames_S1_2560x1440_2560x1440-ec64e621befe963c25f91493a9c7fbfa?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Fallout: A Post Nuclear Role Playing Game",
        "store": "Epic Games Store",
        "gamedescription": "You've just unearthed the classic post-apocalyptic role-playing game that revitalized the entire CRPG genre. Make the right decisions or you could end up as another fallen hero in the wastelands…",
        "gamelink": "https://store.epicgames.com/p/fallout",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/2e412cfcbd954e7180930e9784a47e18/EGS_FalloutAPostNuclearRolePlayingGame_BethesdaGameStudios_S3_2560x1440-548598863fb854e957d7706a24c6d381?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Fallout Tactics: Brotherhood of Steel",
        "store": "Epic Games Store",
        "gamedescription": "Tactical Squad-Based Combat comes to the Fallout® Universe! In these dark times, the Brotherhood - your Brotherhood - is all that stands between the rekindled flame of civilization and the howling, radiated wasteland.",
        "gamelink": "https://store.epicgames.com/p/fallout-tactics-brotherhood-of-steel",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/b90be973f6e74975ac1a6ff15dbfd0b3/EGS_FalloutTacticsBrotherhoodofSteel_BethesdaGameStudios_S1_2560x1440-36cd52e1846a29ad9f0cf60dff64d210?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Fallout 2: A Post Nuclear Role Playing Game",
        "store": "Epic Games Store",
        "gamedescription": "Fallout® 2 is the sequel to the critically acclaimed game that took RPG'ing out of the dungeons and into a dynamic, apocalyptic retro-future. Mastering your character's skills and traits for survival, Fallout® 2 challenges you to endure in a post-nuclear world",
        "gamelink": "https://store.epicgames.com/p/fallout-2",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/aae22da1fb884fa395b25bc30dd61faf/EGS_Fallout2APostNuclearRolePlayingGame_BethesdaGameStudios_S1_2560x1440-4cfbea54e590ef00cb9c440ac3b3a36c?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "LEGO® Builder's Journey",
        "store": "Epic Games Store",
        "gamedescription": "LEGO Builder’s Journey is an atmospheric,  geometric puzzle game that asks us to sometimes follow the instructions…  and sometimes to break the rules.",
        "gamelink": "https://store.epicgames.com/p/lego-builders-journey",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_LEGOBuildersJourney_LightBrickStudio_S1_2560x1440-6ae618ef78b4fe194f6f349a4fae9101?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Wolfenstein: The New Order",
        "store": "Epic Games Store",
        "gamedescription": "Wolfenstein®: The New Order reignites the  series that created the first-person shooter genre. Set against a  backdrop of an alternate 1960s, discover an unfamiliar world ruled by a  familiar enemy—one that has changed and twisted history as you know it.",
        "gamelink": "https://store.epicgames.com/p/wolfenstein-the-new-order",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_WolfensteinTheNewOrder_MachineGames_S1_2560x1440-3a75b2c45a2a12e882feb2e2ff180b0c?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Them's Fightin' Herds",
        "store": "Epic Games Store",
        "gamedescription": "Them’s Fightin’ Herds is a 2D fighting game  featuring a cast of adorable animals designed by acclaimed cartoon  producer Lauren Faust. Beneath the cute and cuddly surface, a serious  fighter awaits!",
        "gamelink": "https://store.epicgames.com/p/thems-fightin-herds",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/a567c32599bf4d4fb609e424f3691b39/EGS_ThemsFightinHerds_Mane6Inc_S1_2560x1440-da3a940faafe30c602d3966d2c295825?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Broken Sword: Director's Cut",
        "store": "GoG",
        "gamedescription": "Uncover the identity of a jester assassin. Unlock the legend of the Knights Templar. Unravel a plot as twisted as the catacombs you're about to prowl. As George Stobbart, an American in Paris, you find it odd when an accordion-playing clown darts out of a cafe clutching a briefcase. Moments later, you're sent flying, violently thrown through the air by the force of a massive explosion, right into a sinister, global intrigue. The coveted contents of that briefcase? A fiercely guarded manuscript penned by a clandestine medieval order - the Knights Templar. Reveal the truth behind a secret conspiracy and save the world from the Templars' evil plan. The  Director's Cut includes an additional chapter of the story, seen from  Nico's perspective and the original version of Broken Sword: Shadow of  the Templars a.k.a. Broken Sword: Circle of Blood. Great hand-drawn graphics, now remade with new animated cinematics and HD resolutions. A timeless adventure game with a conspiracy atmosphere and a big underlying mystery",
        "gamelink": "https://www.gog.com/game/broken_sword_directors_cut",
        "gamecoverurl": "https://images.gog-statics.com/df7ea31ebc217005ed5e246b85c42e95cc35e0d4db6bb60b199a934a796c4f3c_product_card_v2_mobile_slider_639.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Sable (Only for 24H!)",
        "store": "Epic Games Store",
        "gamedescription": "Embark on a unique and unforgettable journey and guide Sable through her Gliding, a rite of passage that will take her across vast deserts and mesmerizing landscapes, capped by the remains of spaceships and ancient wonders.",
        "gamelink": "https://store.epicgames.com/p/sable",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_Sable_Shedworks_S1_2560x1440-64ec090eed8335263a9083de0eb7f5cf?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Costume Quest 2",
        "store": "Epic Games Store",
        "gamedescription": "Explore spooky time-traversing landscapes, don adorable new costumes that transform into powerful Hallo-warriors, and collect even Creepier Treat Cards to wield in combat against a legion of hygiene obsessed baddies.",
        "gamelink": "https://store.epicgames.com/p/costume-quest-2",
        "gamecoverurl": "https://cdn1.epicgames.com/c660f57e1a164930a7196f1fd27ae45a/offer/EGS_SequeltotheHalloweenadventurefromDoubleFine_DoubleFine_G1C_00-1920x1080-18c7498869f8989a44fdccdc27fd07a4.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "King of Seas",
        "store": "GoG",
        "gamedescription": "King of Seas is an Action Role playing game set in a deadly procedurally generated pirate world. In a ferocious plot you will fight  to regain what has been taken away and embark on an epic adventure in a fantastic world, filled with battles, lost islands and treasures. A universe full of amazing characters and breathtaking missions will keep you anchored as you strive to become the king of all pirates.",
        "gamelink": "https://www.gog.com/en/game/king_of_seas",
        "gamecoverurl": "https://img.youtube.com/vi/OKOil_pbTV0/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Call of the Wild: The Angler™ – Norway Reserve",
        "store": "Steam",
        "gamedescription": "This content requires the base game Call of the Wild: The Angler™ on Steam in order to play. Experience a whole new Nordic fishing reserve – claim for free until Jan 5th, 2023Your  continued support and feedback is invaluable to us, as we continue to  work hard on expanding and improving the game. As a token of our thanks,  we are making the Norway Reserve available for free to all our loyal  players.",
        "gamelink": "https://store.steampowered.com/app/2165500/Call_of_the_Wild_The_Angler__Norway_Reserve/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/2165500/header.jpg?t=1676563481",
        "freeuntil": "expired"
    },
    {
        "gamename": "Ghost of a Tale",
        "store": "GoG",
        "gamedescription": "Ghost of a Tale is an action-RPG game in which you play as Tilo, a mouse and minstrel caught up in a perilous adventure. The game takes place in a medieval world populated only by animals, and puts an emphasis on immersion and exploration. It features stealth elements, disguises, conversations with allies and enemies, and quests.",
        "gamelink": "https://www.gog.com/game/ghost_of_a_tale",
        "gamecoverurl": "https://img.youtube.com/vi/TRvrZSNWjNc/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Capcom Arcade Stadium: FINAL FIGHT (DLC)",
        "store": "Steam",
        "gamedescription": "Add FINAL FIGHT to your Capcom Arcade Stadium game line-up! The criminal Mad Gear Gang dominates the streets of Metro City. At the  first sign that Mayor Mike Haggar would oppose the criminals, his family  too became a target of their cruelty. For the sake of the city, three  brave vigilantes must stand against this evil!",
        "gamelink": "https://store.steampowered.com/app/1556712/Capcom_Arcade_StadiumFINAL_FIGHT/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/1556712/header.jpg?t=1668019288",
        "freeuntil": "expired"
    },
    {
        "gamename": "Wildcat Gun Machine",
        "store": "Epic Games Store",
        "gamedescription": "Wildcat Gun Machine is an  explosion roller coaster ride! Enter a bullet hell dungeon crawler where  you take on hordes of disgusting flesh beasts with a wide variety of  guns, giant mech robots, and cute kittens.",
        "gamelink": "https://store.epicgames.com/p/wildcat-gun-machine-c66c4e",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/d92266115f8d4d5680562d993435daa5/wildcat-gun-machine-offer-rle9l.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Saints Row IV Re-Elected",
        "store": "Epic Games Store",
        "gamedescription": "You're the President now! The Saints have gone  from the Penthouse to the White House - but Earth has been invaded and  it’s up to you to save it with an arsenal of superpowers and strange  weapons, in the wildest open world game ever.",
        "gamelink": "https://store.epicgames.com/p/saints-row-iv-re-elected",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/151e56468b5049628653dedab7c88007/EGS_SaintsRowIVReElected_DeepSilverVolition_S1_2560x1440-77ce5721cb572e6ce6f37537edcb4fb1?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "PUBG Founder's Pack (DLC)",
        "store": "Epic Games Store",
        "gamedescription": "Claim your PUBG Founder's Pack and land on the fierce battlefield! To celebrate our Epic Games Store launch, you'll be able to claim the PUBG Founder's Pack for free until January 5th!",
        "gamelink": "https://store.epicgames.com/p/pubg-pubg-founders-pack-b325e9",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/53ec4985296b4facbe3a8d8d019afba9/pubg-battlegrounds-1hfa6.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Fort Triumph",
        "store": "Epic Games Store",
        "gamedescription": "A strategy game combining the turn-based  combat of XCOM with the world exploration of HOMM. Build towns, collect  artifacts, improve your heroes, and influence your tactical surroundings  using physics!",
        "gamelink": "https://store.epicgames.com/p/fort-triumph",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_FortTriumph_CookieByteEntertainment_S1_2560x1440-a0b5e1aeb62c1ee5550d97886ab475a3?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "RPG in a Box",
        "store": "Epic Games Store",
        "gamedescription": "Bring  your stories and ideas to life! RPG in a Box is an accessible game  creation tool for making games and other interactive experiences in a  fun and simple way.",
        "gamelink": "https://store.epicgames.com/p/rpg-in-a-box",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/0e76e53e157d416ab598342ed3a9dd5a/EGS_RPGinaBox_JustinArnold_S1_2560x1440-fef2661fad4d06cee988aa2a2c3383c7?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Terroir",
        "store": "GoG",
        "gamedescription": "Terroir is a 3D tile-based tycoon game where you manage your very own  vineyard. In Terroir, you grow a variety of different grape varietals,  craft your wine, and expand and manage your Estate. You'll also have to  deal with factors such as weather and random events, which can either  make or break your business.",
        "gamelink": "https://www.gog.com/en/game/terroir",
        "gamecoverurl": "https://img.youtube.com/vi/faLh0s99VM8/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "STAR WARS™: Squadrons",
        "store": "Epic Games Store",
        "gamedescription": "Master the art of starfighter combat in the authentic piloting experience STAR  WARS™: Squadrons. Buckle up, feel the adrenaline of first-person  multiplayer space dogfights alongside your squadron, and learn what it  means to be a pilot in a thrilling STAR WARS™ single-player story.",
        "gamelink": "https://store.epicgames.com/p/star-wars-squadrons",
        "gamecoverurl": "https://cdn1.epicgames.com/bc2b0372eaa448469cb9a769f27acea1/offer/wide-2560x1440-f09b204b4eb17f85e997a247901b8d81.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Narita Boy",
        "store": "GoG",
        "gamedescription": "Narita Boy becomes a tremendous hit! Copies of cartridges are flying off  physical shelves worldwide. Within weeks Narita Boy is the best-selling  video game of all time, critically acclaimed for its power-fantasy  wielding the Techno-sword and taking players on a journey like no other. Meanwhile, inside the binary code, the digital realm connects with  reality. Him has returned and deleted The Creator’s memories. Supervisor  program, Motherboard, and her agents have activated the Narita Boy  protocol. The Stallions are coming, and the Digital Kingdom needs a hero.",
        "gamelink": "https://www.gog.com/game/narita_boy",
        "gamecoverurl": "https://img.youtube.com/vi/rVM_eEe09iA/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Evil Dead: The Game",
        "store": "Epic Games Store",
        "gamedescription": "Step into the shoes of Ash Williams or his friends from the iconic Evil Dead franchise and work together in a game loaded with over-the-top co-op and PVP multiplayer action!",
        "gamelink": "https://store.epicgames.com/p/evil-dead-the-game",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/95e09a8c24c44684bca1a6f25aea920c/EGS_EvilDeadTheGame_SaberInteractiveInc_S1_2560x1440-fde136bc32f2b93006b9df0bc421fec0_2560x1440-fde136bc32f2b93006b9df0bc421fec0?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Dark Deity",
        "store": "Epic Games Store",
        "gamedescription": "Step into the world of Terrazael and lead soon-to-be legendary heroes in a quest to restore a land fractured by broken oaths, reckless wars, and untamed magic. Fight back in turn-based battles, manage character relationships, collect dangerous artifacts, and restore the world.",
        "gamelink": "https://store.epicgames.com/p/dark-deity-0b08d1",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/1d1ceda7b1ba4e7893a36a1166ac3e41/dark-deity-offer-1v77p.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Alba - A Wildlife Adventure",
        "store": "Epic Games Store",
        "gamedescription": "Even the smallest person can make a big  difference. Join Alba, as she sets out to save her beautiful island and  its wildlife. And possibly start a revolution.",
        "gamelink": "https://store.epicgames.com/p/alba-a-wildlife-adventure-93736a",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/c816cec291404812a9eacc03387dcbca/alba--a-wildlife-adventure-offer-16frq.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Shadow Tactics",
        "store": "Epic Games Store",
        "gamedescription": "Shadow Tactics is a hardcore tactical stealth  game set in Japan around the Edo period. A new Shogun seizes power over  Japan and enforces nationwide peace. He recruits five specialists with  extraordinary skills for assassination, sabotage and espionage.",
        "gamelink": "https://store.epicgames.com/p/shadow-tactics",
        "gamecoverurl": "https://cdn1.epicgames.com/undefined/offer/EGS_MimimiGames_ShadowTacticsBladesoftheShogun_S3-1360x766-216c2e04b177c6b7252f1f535edef030.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Warhammer: Vermintide 2",
        "store": "Steam",
        "gamedescription": "The critically acclaimed Vermintide 2 is a visually stunning and  groundbreaking melee action game pushing the boundaries of the first  person co-op genre. Join the fight now!",
        "gamelink": "https://store.steampowered.com/app/552500/Warhammer_Vermintide_2/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/552500/header.jpg?t=1676513104",
        "freeuntil": "expired"
    },
    {
        "gamename": "Filament",
        "store": "Epic Games Store",
        "gamedescription": "Solve challenging cable-based puzzles and  uncover what really happened to the crew of The Alabaster.",
        "gamelink": "https://store.epicgames.com/p/filament-332a92",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/5a72e62648d747189d2f5e7abb47444c/filament-offer-qrwye.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Rising Storm 2: Vietnam",
        "store": "Epic Games Store",
        "gamedescription": "Red Orchestra Series' take on Vietnam:  64-player MP matches, 20+ maps, US Army & Marines, PAVN/NVA, NLF/VC,  Australians and ARVN forces, 50+ weapons, 4 flyable helicopters, mines,  traps and tunnels, Brutal. Authentic. Gritty. Character customization.",
        "gamelink": "https://store.epicgames.com/p/rising-storm-2-vietnam",
        "gamecoverurl": "https://cdn1.epicgames.com/3542a1df211e492bb2abecb7c734f7f9/offer/EGS_RisingStorm2Vietnam_AntimatterGamesTripwireInteractive_S3-2560x1440-e08edd93cb71bf15b50a74f3de2d17b0.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Genesis Alpha One Deluxe Edition",
        "store": "GoG",
        "gamedescription": "In a near future ravaged by wars, corrupt regimes and devastating  pollution, influential corporations have created the Genesis program in a  last-ditch attempt to save humanity. As the Captain of a Genesis starship, you journey into uncharted space  on the ultimate mission. Build and manage a space vessel, farm  resources, deal with terrifying alien infestations, clone creatures and  explore a vast, randomly generated universe.",
        "gamelink": "https://www.gog.com/en/game/genesis_alpha_one_deluxe_edition",
        "gamecoverurl": "https://img.youtube.com/vi/wCtVf_iC1fE/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Warhammer 40,000: Mechanicus",
        "store": "Epic Games Store",
        "gamedescription": "Take control of the most technologically advanced army in the Imperium - The Adeptus Mechanicus. Your every decision will weigh heavily on the outcome of the mission, in this turn-based tactical game.",
        "gamelink": "https://store.epicgames.com/p/warhammer-mechanicus-0e4b71",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/d26f2f9ea65c462dbd39040ae8389d36/warhammer-mechanicus-offer-17fnz.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Saturnalia",
        "store": "Epic Games Store",
        "gamedescription": "A Survival Horror Adventure: as an ensemble cast, explore an isolated village of ancient ritual – its labyrinthine roads change each time you lose all your characters.",
        "gamelink": "https://store.epicgames.com/p/saturnalia",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/c749cd78da34408d8434a46271f4bb79/EGS_Saturnalia_SantaRagione_S3_2560x1440-3cd916a7260b77c8488f8f2b0f3a51ab?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Fallout 3: Game of the Year Edition",
        "store": "Epic Games Store",
        "gamedescription": "Prepare for the Future™ Experience the most  acclaimed game of 2008 like never before with Fallout 3: Game of the  Year Edition. Create a character of your choosing and descend into a  post-apocalyptic world where every minute is a fight for survival",
        "gamelink": "https://store.epicgames.com/p/fallout-3-game-of-the-year-edition",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/fa702d34a37248ba98fb17f680c085e3/EGS_Fallout3GameoftheYearEdition_BethesdaGameStudios_S1_2560x1440-073f5b4cf358f437a052a3c29806efa0?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Evoland Legendary Edition",
        "store": "Epic Games Store",
        "gamedescription": "Evoland Legendary Edition brings you two great  and unique RPGs, with their graphic style and gameplay changing as you  progress through the game!",
        "gamelink": "https://store.epicgames.com/p/evoland-legendary-edition-5753ec",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/aafde465b31e4bd5a169ff1c8a164a17/evoland-legendary-edition-1y7m0.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "ToeJam & Earl: Back in the Groove!",
        "store": "Epic Games Store",
        "gamedescription": "ToeJam & Earl: Back in the Groove is a  mash-up of the best features from the classic console games with a ton  of funky fresh improvements.  Relive the original roguelike game with new 4 player online and local  co-op, crazy Earthlings, weird presents and aliens from Funkotron!",
        "gamelink": "https://store.epicgames.com/p/toejam-and-earl-back-in-the-groove-cddc16",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/428b51d4cd29482889841fa188b362b4/toejam-and-earl-back-in-the-groove-offer-15w7f.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Darkwood",
        "store": "Epic Games Store",
        "gamedescription": "Darkwood  - a new perspective on survival horror. Scavenge and explore a rich,  ever-changing free-roam world by day, then hunker down in your hideout  and pray for the morning light.",
        "gamelink": "https://store.epicgames.com/p/darkwood-fa73bd",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/f98517036ab5480ebd9c68d7249188b4/darkwood-1vcl2.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Slain: Back From Hell",
        "store": "Epic Games Store",
        "gamedescription": "Slain: Back from Hell. A heavy metal inspired  arcade combat with puzzle elements and gore galore. Stunning visuals and  true old school gameplay accompanied by the most metal soundtrack  you've ever heard.",
        "gamelink": "https://store.epicgames.com/p/slain-aea303",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/c314efd052d048908cbd57ce7bf9e28a/slain-offer-11h32.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Rising Hell",
        "store": "Epic Games Store",
        "gamedescription": "Rising Hell is a vertical platformer  rogue-lite filled with adrenaline-pumping action and tons of heavy  gothic metal shreds. Climb out from the ever-changing hell while you  fight hordes of blood-lusting demons.",
        "gamelink": "https://store.epicgames.com/p/rising-hell-253707",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/8f7e0537b57d4a14bdd11a3e719973a1/rising-hell-offer-7nnkf.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Drone Racing League Simulator",
        "store": "Epic Games Store",
        "gamedescription": "The DRL Simulator is the premier FPV racing  game and simulator. The DRL Sim offers a robust, evolving feature set,  including high-intensity drone racing. It packs tracks that DRL's real  world pilots compete on, with more added every season.",
        "gamelink": "https://store.epicgames.com/p/the-drone-racing-league-simulator",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_TheDroneRacingLeagueSimulator_TheDroneRacingLeague_S1_2560x1440-39456325d9a12441a58f5385b5719224?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Runbow",
        "store": "Epic Games Store",
        "gamedescription": "Runbow is the party platformer you've been  waiting for! With tons of characters, costumes, and crazy game modes,  Runbow is full of  colourful chaos that you just have to experience for  yourself.",
        "gamelink": "https://store.epicgames.com/p/runbow-fc9fa4",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/c4dde13fcdea4aaa84dabb303006a870/runbow-offer-v8wvy.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Gloomhaven",
        "store": "Epic Games Store",
        "gamedescription": "Whether you are drawn to Gloomhaven by the call of adventure or by an avid desire for gold glimmering in the dark, your fate will surely be the same. Gloomhaven, the digital adaptation of the acclaimed board game, mixes Tactical-RPG and dungeon-crawling.",
        "gamelink": "https://store.epicgames.com/p/gloomhaven-92f741",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/ef2777467a3c49059a076e42fd9b41f0/gloomhaven-offer-1j9mc.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "ARK: Survival Evolved",
        "store": "Epic Games Store",
        "gamedescription": "Stranded on the shores of a mysterious island, you must learn to survive. Use your cunning to kill or tame the primeval creatures roaming the land, and encounter other players to survive, dominate... and escape!https://store.epicgames.com/p/ark",
        "gamelink": "https://store.epicgames.com/p/ark",
        "gamecoverurl": "https://cdn1.epicgames.com/ark/offer/EGS_ARKSurvivalEvolved_StudioWildcard_S1-2560x1440-c316afb7c33a9dfb892eef6b99169e43.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Captain",
        "store": "Epic Games Store",
        "gamedescription": "Race back home across the galaxy. Collect new friends. Make new enemies. You won't be able to save everyone. Who will you leave behind? Which civilizations will you save - or destroy - along the way? The decisions are up to you.",
        "gamelink": "https://store.epicgames.com/p/the-captain",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_TheCaptain_Sysiacgames_S1_2560x1440-b58c40c02e67f0e2317a62c4b51d5c9f?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Spirit of the North",
        "store": "Epic Games Store",
        "gamedescription": "Spirit of the North is a single-player 3rd-person adventure game inspired by the breathtaking and mysterious landscapes of Iceland. The story takes root from various pieces of Nordic folklore.",
        "gamelink": "https://store.epicgames.com/p/spirit-of-the-north-f58a66",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/177dc72233934ac487abd83b01587086/spirit-of-the-north-offer-mznb7.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Hundred Days - Winemaking Simulator",
        "store": "Epic Games Store",
        "gamedescription": "Winemaking could be your best adventure. Make the best wine interacting with soil and nature and take your winery to the top. Your beautiful journey into the winemaking tradition starts now.",
        "gamelink": "https://store.epicgames.com/p/hundred-days-winemaking-simulator",
        "gamecoverurl": "https://cdn1.epicgames.com/4d8b727a49144090b103f6b6ba471e71/offer/EGS_HundredDaysWinemakingSimulatorDEMO_BrokenArmsGames_Demo_G1C_00-1920x1080-0ffeb0645f0badb615627b481b4a913e.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Realm Royale Reforged (Epic Launch Bundle)",
        "store": "Epic Games Store",
        "gamedescription": "Realm Royale is Bok with Realm Royale Reforged! Dive back in with this free Epic Games exclusive bundle for a limited time! The Epic Launch Bundle instantly unlocks the Death's Edge Assassin Skin, Bokserker Chicken Skin and the Nogard Mount!",
        "gamelink": "https://store.epicgames.com/p/realm-royale-reforged--epic-launch-bundle",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/5fcf6f3031c547f789e29c18b422ca67/LandscapeOfferIMG-2560x1440_2560x1440-fcac17efbfeba5298a67d3869d9f8dd0?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Immortal Redneck",
        "store": "GoG",
        "gamedescription": "Immortal Redneck is an FPS set in Egypt with rogue-lite elements. The game mixes old-school first-person shooter action with a rogue-lite mechanics. Frantic gameplay, twitch controls and an arcade-style feel meet randomly generated dungeons, a complete skill tree, permanent death and 9 classes with different traits.",
        "gamelink": "https://www.gog.com/en/game/immortal_redneck",
        "gamecoverurl": "https://img.youtube.com/vi/giF-Ix_NJw8/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Submerged: Hidden Depths",
        "store": "Epic Games Store",
        "gamedescription": "Boat, climb, interact and explore in the beautiful ruins of a sunken world.",
        "gamelink": "https://store.epicgames.com/p/submerged-hidden-depths-6065a1",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/5409bde4ade34d51a33baabe6ad10234/submerged-hidden-depths-offer-1cs6h.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Mafia",
        "store": "Steam",
        "gamedescription": "It’s 1930. After an inadvertent brush with the mafia, cabdriver Tommy Angelo is reluctantly thrust into the world of organized crime. Initially, he is uneasy about falling in with the Salieri family, but soon the rewards become too big to ignore.",
        "gamelink": "https://store.steampowered.com/app/40990/Mafia/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/40990/header.jpg?t=1661895807",
        "freeuntil": "expired"
    },
    {
        "gamename": "Knockout City™",
        "store": "Epic Games Store",
        "gamedescription": "Knockout City is now Free-to-Play, inviting brawlers everywhere to experience the frantic, non-stop action of dodgebrawl. Team up and duke it out with rival Crews in EPIC DODGEBALL BATTLES.",
        "gamelink": "https://store.epicgames.com/p/knockout-city",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/5bb3d43818ae4188850fe1dbad9d57a5/S8_Epic_Native-YT-Thumbnail_1280x720-d76e479f6319bb968c5af7fa568893de?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Shadow of the Tomb Raider: Definitive Edition",
        "store": "Epic Games Store",
        "gamedescription": "In Shadow of the Tomb Raider Definitive Edition experience the final chapter of Lara’s origin as she is forged into the Tomb Raider she is destined to be.",
        "gamelink": "https://store.epicgames.com/p/shadow-of-the-tomb-raider",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/4b5461ca8d1c488787b5200b420de066/egs-shadowofthetombraiderdefinitiveedition-eidosmontralcrystaldynamicsnixxessoftware-s1-2560x1440-eca6506e95a1_2560x1440-193582a5fd76a593804e0171d6395cf4?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Lovecraft's Untold Stories",
        "store": "GoG",
        "gamedescription": "Lovecraft’s Untold Stories is an action roguelite with RPG elements. You explore randomly generated levels based in H.P. Lovecraft stories, fighting cultists and all kinds of monsters from the Mythos, improving your weapons and gear, solving puzzles and challenges, and looking for clues and knowledge to defeat the Great Old Ones and the Outer Gods.",
        "gamelink": "https://www.gog.com/en/game/lovecrafts_untold_stories",
        "gamecoverurl": "https://img.youtube.com/vi/CaBRBq671x0/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Dex",
        "store": "GoG",
        "gamedescription": "Wanted dead by a mysterious and far-reaching organization, you will traverse the futuristic city of Harbor Prime and make unexpected allies on your quest to bring down the system! In this cyberpunk adventure inspired by Blade Runner and Neuromancer, you are free to choose your playstyle: will you be a silent assassin, a hacker, a diplomat or a straightforward gunfighter?",
        "gamelink": "https://www.gog.com/en/game/dex",
        "gamecoverurl": "https://img.youtube.com/vi/RAnXOyCw2Aw/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Ring of Pain",
        "store": "Epic Games Store",
        "gamedescription": "Enter the Ring of Pain, a roguelike card crawler where encounters come to you. Each step around the ring a dire decision. Go for the loot or backstab a creeping horror?",
        "gamelink": "https://store.epicgames.com/p/ring-of-pain",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/b9c7474c6bdf4e9ab8ca5a5da60059d4/EGS_RingofPain_SimonBoxerTwiceDifferent_S1_2560x1440-a913b4a4f4ad707131f0d3a681847afa?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Destiny 2: Bungie 30th Anniversary Pack",
        "store": "Epic Games Store",
        "gamedescription": "The 30th Anniversary Pack includes a new Dungeon, Gjallarhorn Exotic Rocket Launcher, new weapons, armor, and much more.",
        "gamelink": "https://store.epicgames.com/p/destiny-2--bungie-30th-anniversary-pack",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/428115def4ca4deea9d69c99c5a5a99e/EGS_Destiny2Bungie30thAnniversaryPack_Bungie_AddOn_S1_2560x1440-40e931364bcfa578a0b3b9daed1774bf?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "STASIS",
        "store": "GoG",
        "gamedescription": "STASIS is a point-and-click, sci-fi, horror adventure game played from a unique isometric perspective. STASIS intertwines a gruesome narrative with retro adventure gameplay. Combine items, interact with computers and solve puzzles while experiencing the gritty atmosphere.",
        "gamelink": "https://www.gog.com/en/game/stasis",
        "gamecoverurl": "https://img.youtube.com/vi/LgLf8AqxNnY/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "DOOM 64",
        "store": "Epic Games Store",
        "gamedescription": "Play the enhanced version of the original game released on the Nintendo 64 in 1997. Years have passed since you stopped Hell’s invasion of Earth. Quarantined for humanity’s safety, the UAC research facilities on Mars were abandoned and forgotten...until now.",
        "gamelink": "https://store.epicgames.com/p/doom-64",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/0ea70edafcc54b3191e262bbb6971981/EGS_DOOM64_idSoftwareNightdiveStudios_S1_2560x1440-ad0080b34010d3c86254227a14e96494?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Rumbleverse™ - Boom Boxer Content Pack (DLC)",
        "store": "Epic Games Store",
        "gamedescription": "This bundle includes the exclusive Boom Boxer set, an exclusive title card background and border, and a 120 minute gameplay Fame booster.",
        "gamelink": "https://store.epicgames.com/p/rumbleverse--boom-boxer-content-pack",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/0bef9383794a4d779ba0628084b14cba/EPIC_RV_PLATFORM_PACKLANDSCAPE_2560x1440_2560x1440-3f6a19708cf245a422894a330b900b1f?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "MultiVersus",
        "store": "Epic Games Store",
        "gamedescription": "MultiVersus is a platform fighter that lets you team up with your friends using some of the most iconic characters including Batman, Shaggy, & more. Find your favorite fighter combos and save the Multiverse!",
        "gamelink": "https://store.epicgames.com/p/multiversus",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/3a212c0da4f1438e840c21565df4b6fe/EGS_MultiVersus_PlayerFirstGames_S1_2560x1440-0c97c9a155748f90f13fc0dd484ccc48?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Cook, Serve, Delicious! 3?!",
        "store": "Epic Games Store",
        "gamedescription": "Cook, serve and manage your food truck as you dish out hundreds of different foods across war-torn America in this massive sequel to the million-selling series!",
        "gamelink": "https://store.epicgames.com/p/cook-serve-delicious-3-fb9aae",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/28430738e6554ff0a67d9a93402c4348/cook-serve-delicious-3-offer-11zbq.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Despotism 3k",
        "store": "Steam",
        "gamedescription": "Humanity is enslaved by an AI… which is awesome, because we’re on the right side of the conflict. Exploit puny humans to extract power and build your own empire!",
        "gamelink": "https://store.steampowered.com/app/699920/Despotism_3k/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/699920/header.jpg?t=1663079894",
        "freeuntil": "expired"
    },
    {
        "gamename": "Unrailed!",
        "store": "Epic Games Store",
        "gamedescription": "Unrailed! is a co-op multiplayer game where you have to work together with your friends to build a train track across endless procedurally generated worlds. Master random encounters with its inhabitants, upgrade your train and keep it from derailing!",
        "gamelink": "https://store.epicgames.com/p/unrailed-e19729",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/dc57439aac3c481aaa52ccb443a4d01a/unrailed-offer-16fiw.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "POLE",
        "store": "Steam",
        "gamedescription": "POLE is a challenging NES/Retro style precision platformer. You have a POLE (like a stick), and you use it to jump off surfaces, like walls, ceilings or floors. POLE has tough to beat levels and a rewarding story. Left Shift or Right Trigger to Sprint, trust me, you need to.",
        "gamelink": "https://store.steampowered.com/app/1646730/POLE/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/1646730/header.jpg?t=1655163608",
        "freeuntil": "expired"
    },
    {
        "gamename": "Lawn Mowing Simulator",
        "store": "Epic Games Store",
        "gamedescription": "Experience the beauty and detail of mowing the Great British countryside in Lawn Mowing Simulator, the only simulator that allows you to ride an authentic and expansive roster of real-world licensed lawn mowers from prestigious manufacturers as you manage your business.",
        "gamelink": "https://store.epicgames.com/p/lawn-mowing-simulator-838bf3",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/977ce2dee0fe437d90d2a67adf9917d8/lawn-mowing-simulator-offer-1615h.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Shop Titans",
        "store": "Epic Games Store",
        "gamedescription": "Shop Titans is the ultimate RPG shopkeeper simulation. Craft powerful equipment, stock your shop and sell to aspiring heroes… at a markup! Hire heroes and explore dungeons to gather valuable materials to craft with. There’s never a dull moment in the world of Shop Titans!",
        "gamelink": "https://store.epicgames.com/p/shop-titans-d95c1c",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/8ae16e8a55fa4f3897fa726a90dbd750/shop-titans-offer-sw9vn.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Tannenberg",
        "store": "Epic Games Store",
        "gamedescription": "Experience authentic First World War action with 64 player battles of maneuver on the Eastern Front. Grab your rifle, ready your sabre and keep your gas mask close as you prepare to play a part in one of the most significant conflicts in history!",
        "gamelink": "https://store.epicgames.com/p/tannenberg",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_Tannenberg_M2HBlackmillGames_S1_2560x1440-ac40112254aee2c0d5b1f9e488c41a11?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Wonder Boy: The Dragon's Trap",
        "store": "Epic Games Store",
        "gamedescription": "Boasting beautiful, hand-drawn animations and a re-orchestrated soundtrack, the cult classic returns with a unique blend of exploration, action, and adventure",
        "gamelink": "https://store.epicgames.com/p/wonder-boy-the-dragons-trap-26381d",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/e488839155be4d05bb14f6e05129daef/wonder-boy-the-dragons-trap-offer-3hg29.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Killing Floor 2",
        "store": "Epic Games Store",
        "gamedescription": "In KILLING FLOOR 2, players descend into continental Europe after it has been overrun by horrific, murderous clones called Zeds that were created by rogue elements of the Horzine Corporation. 6-player Co-Op & 12-player VS Zed-slaughtering mayhem.",
        "gamelink": "https://store.epicgames.com/p/killing-floor-2",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/affc33b82405457595a032f00284abd2/KF2_2560x1440-4995a845423263335d529fcae667e979?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Iratus: Lord of the Dead",
        "store": "Epic Games Store",
        "gamedescription": "Iratus: Lord of the Dead is a turn-based tactical roguelike RPG set in a dark fantasy universe. Lead an army of undead to help an angry necromancer in his quest to reach the surface world and bring death to the mortal realms!",
        "gamelink": "https://store.epicgames.com/p/iratus-d0e5ba",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/e7bee97d9a88475487a71b456a81110f/iratus--lord-of-the-dead-offer-i34hd.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Geneforge 1: Mutagen",
        "store": "Epic Games Store",
        "gamedescription": "Unique, open-ended fantasy RPG in an alien land. Use battle or cunning to change the world, served by your army of custom-made mutant monsters. Tons of skills, treasures, factions, treasures, and creations. Unmatched freedom and replayability in an epic story.",
        "gamelink": "https://store.epicgames.com/p/geneforge-1-mutagen",
        "gamecoverurl": "https://cdn1.epicgames.com/308652d49c89470fbf45fe8696b9c1c1/offer/egs-geneforge1mutagen-spiderwebsoftware-s1-2560x1440-c8cf464f2b2a-2560x1440-8427125eb377278c6aed260c7900f2b6.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Hood: Outlaws & Legends",
        "store": "Epic Games Store",
        "gamedescription": "In a violent, medieval world, outplay rival gangs in intense PvPvE multiplayer heists. Moving in stealth to steal treasures unseen or dominating through loud and brutal combat, only the best will escape with hard-earned riches.",
        "gamelink": "https://store.epicgames.com/p/hood-outlaws-and-legends",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_HoodOutlawsLegends_SumoDigital_S1_2560x1440-8ba1f9be04e3d0c07f9890a0bb8860c7?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "VirtuaVerse",
        "store": "GoG",
        "gamedescription": "Playing the STORY MODE would be a great option for all those people that don't want to deal with hardcore puzzles. You won't miss much of the story but at the same time most of puzzles will be easier, require less conditions to be unlocked and you will deal with much less inventory objects. Some passages have been cut but the big part of the game is still there. If you are among those who gave up because the game felt too difficult this is also a good opportunity to give it a try again. Also you can still try the old school mode again once you have completed the story mode.",
        "gamelink": "https://www.gog.com/game/virtuaverse",
        "gamecoverurl": "https://img.youtube.com/vi/-sjjpMRxLOc/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "A Game Of Thrones: The Board Game Digital Edition",
        "store": "Epic Games Store",
        "gamedescription": "A Game of Thrones: The Board Game Digital Edition is the digital adaptation of the top-selling strategy board game from Fantasy Flight Games.",
        "gamelink": "https://store.epicgames.com/p/a-game-of-thrones-5858a3",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/61c1413e3db0423f9ddd4a5edbee717e/a-game-of-thrones-offer-11gxu.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Fall Guys",
        "store": "Epic Games Store",
        "gamedescription": "Fall Guys is a free, cross-platform, massively multiplayer, party royale game where you and your fellow contestants compete through escalating rounds of absurd obstacle course chaos until one lucky victor remains!",
        "gamelink": "https://store.epicgames.com/p/fall-guys",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/50118b7f954e450f8823df1614b24e80/EGS_FallGuys_Mediatonic_S1_2560x1440-187aa50ffaa014885d6702a0b632f848?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Car Mechanic Simulator 2018",
        "store": "Epic Games Store",
        "gamedescription": "Build and expand your repair service empire in this incredibly detailed and highly realistic simulation game, where attention to car detail is astonishing. Find classic, unique cars in the new Barn Find module and Junkyard module.",
        "gamelink": "https://store.epicgames.com/p/car-mechanic-simulator-2018",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/226306adde104c9092247dcd4bfa1499/EGS_CarMechanicSimulator2018_RedDotGames_S1_2560x1440-3489ef1499e64c168fdf4b14926d2c23?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Flashback",
        "store": "GoG",
        "gamedescription": "2142. After fleeing from a space ship but stripped of all memory, the young scientist Conrad B. Hart awakens on Titan, a colonised moon of the planet Saturn. His enemies and kidnappers are snapping at his heels. He must find a way back to Earth, all the while defending himself against the dangers he encounters and unravelling an insidious extra-terrestrial plot that threatens the planet…",
        "gamelink": "https://www.gog.com/game/flashback",
        "gamecoverurl": "https://img.youtube.com/vi/A3kPzocVoRk/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Beautiful Desolation",
        "store": "GoG",
        "gamedescription": "Mark, a man out of time, searches for his lost brother Don, in a far-flung futuristic era ruled by highly advanced technologies which are both revered and reviled. Your surroundings hold echoes of a desolate past, and glimpses of a dark future that has yet to be written by your actions. Be prepared to face many tough choices that will shape this land long after you complete your journey.",
        "gamelink": "https://www.gog.com/game/beautiful_desolation",
        "gamecoverurl": "https://img.youtube.com/vi/fT0uVf2YfWI/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Supraland",
        "store": "Epic Games Store",
        "gamedescription": "A mix between Portal, Zelda and Metroid. Explore, solve puzzles, beat up monsters, find secret upgrades and new abilities that help you reach new places.",
        "gamelink": "https://store.epicgames.com/p/supraland",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_Supraland_SupraGames_S5_1920x1080-3d9ef81461113b21916c011f1d131469?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Daggerfall Unity - GOG Cut",
        "store": "GoG",
        "gamedescription": "Play a reimagined version of the all-time RPG classic from The Elder Scrolls series. Daggerfall Unity – GOG Cut brings this amazing experience to modern gamers. It has been made possible thanks to a whole team of passionate creators working under the banner of Daggerfall Unity. This ambitious project, launched over a decade ago by Gavin “Interkarma” Clayton, is aiming to bring the unique experience of the classic open-world RPG game to a new generation of gamers. Thanks to the efforts of the GamerZakh, a gaming content creator with a love for classics, you can now enjoy a special GOG Cut of the Daggerfall Unity title.",
        "gamelink": "https://www.gog.com/en/game/daggerfall_unity_gog_cut",
        "gamecoverurl": "https://img.youtube.com/vi/z7fmp4gLUjQ/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Shantae and the Pirate's Curse",
        "store": "GoG",
        "gamedescription": "Embark on an all-new adventure with Shantae, the hair-whipping belly dancing genie. After losing her magic, Shantae teams up with the nefarious pirate Risky Boots in order to save Sequin Land from a curse. As a pirate, Shantae gains new weapons to advance her quest, slay monsters, battle epic bosses…and hopefully get her magic back in the bargain!",
        "gamelink": "https://www.gog.com/game/shantae_and_the_pirates_curse",
        "gamecoverurl": "https://img.youtube.com/vi/zY-7_1hk-ho/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Venetica - Gold Edition",
        "store": "GoG",
        "gamedescription": "Set in the fantastic world of long ago Venice, the young Scarlett falls victim to a terrible mystery, which begins with the murder of her companion by a secretive alliance of mighty necromancers. Thus fate takes its inevitable course and threatens to throw the world out of order.",
        "gamelink": "https://www.gog.com/en/game/venetica",
        "gamecoverurl": "https://img.youtube.com/vi/n2M0W1O-Ec4/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Ninja Stealth",
        "store": "Steam",
        "gamedescription": "Evade the guards and the defences to escape the evil facility on this click and play stealth game, there will be difficult on your path, young padawan!",
        "gamelink": "https://store.steampowered.com/app/485450/Ninja_Stealth/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/485450/header.jpg?t=1672603393",
        "freeuntil": "expired"
    },
    {
        "gamename": "Capcom Arcade Stadium：STREET FIGHTER II - The World Warrior",
        "store": "Steam",
        "gamedescription": "This content requires the base game Capcom Arcade Stadium on Steam in order to play.",
        "gamelink": "https://store.steampowered.com/app/1556717/Capcom_Arcade_StadiumSTREET_FIGHTER_II__The_World_Warror/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/1556717/header.jpg?t=1634751754",
        "freeuntil": "expired"
    },
    {
        "gamename": "Dying Light - Harran Tactical Unit Bundle (DLC)",
        "store": "Steam",
        "gamedescription": "Join the Harran Tactical Unit with this new bundle and help your team members pacify the zombie situation! Here, only the best will make it. Become a part of an elite police unit and investigate the dangers lurking in Harran. Form a team with your friends to make sure you’re covered!",
        "gamelink": "https://store.steampowered.com/app/1543420/Dying_Light__Harran_Tactical_Unit_Bundle/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/1543420/header.jpg?t=1657116465",
        "freeuntil": "expired"
    },
    {
        "gamename": "Maneater",
        "store": "Epic Games Store",
        "gamedescription": "Experience the ultimate power fantasy as the apex predator of the seas - a terrifying SHARK! Maneater is a single player, open world action RPG (ShaRkPG) where YOU are the shark. Eat! Explore! Evolve!",
        "gamelink": "https://store.epicgames.com/p/maneater",
        "gamecoverurl": "https://cdn1.epicgames.com/turtle/offer/Maneater_EGS_Landscape_2560x1440-2560x1440-cf27e86a8daeeb2915a49a8b4c954dbd.png?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Sanitarium",
        "store": "GoG",
        "gamedescription": "Imagine a world created out of insanity – deformed children, ancient gods, ghosts from your past in the house inside of a water reflection. You are an asylum patient and you have just survived a car crash – the problem is you can't remember anything. Visions reveal answers to some questions, but create even more riddles! Which part of it is true? What do all these things mean?Sanitarium is one of the few psychological horror adventure games. As the protagonist you will visit five different worlds full of riddles and answers, with which you will have to figure out what exactly is happening and what is real. The story is so incredibly good and enthralling that it could easily drive a sane person mad.",
        "gamelink": "https://www.gog.com/en/game/sanitarium",
        "gamecoverurl": "https://images.gog-statics.com/5040f6869971deb8dcbed9cdd105fbdb45702434e72ecce94cf189771cb27275_product_card_v2_mobile_slider_639.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Warhammer 40,000: Chaos Gate",
        "store": "GoG",
        "gamedescription": "Warhammer 40,000: Chaos Gate (1998 strategy game),Warhammer: Mark of Chaos Concept Art Book,Warhammer wallpapers(+ several discounts)",
        "gamelink": "https://www.gog.com/en/game/warhammer_skulls_2022_digital_goodie_pack",
        "gamecoverurl": "https://images.gog-statics.com/dbeaba9e2ce8992cc4db9b2808731804351fe6efa22e037da63832324970be85_product_card_v2_thumbnail_271.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "BioShock: The Collection",
        "store": "Epic Games Store",
        "gamedescription": "There's always a lighthouse. There's always a man. There's always a city. Experience the unforgettable worlds and monumental stories of the award-winning BioShock series with BioShock: The Collection.",
        "gamelink": "https://store.epicgames.com/bundles/bioshock-the-collection",
        "gamecoverurl": "https://cdn2.unrealengine.com/egs-bioshockthecollection-massmediagames-g1c-00-1920x1080-4269d4245e1e.jpg?h=480&quality=medium&resize=1&w=854",
        "freeuntil": "expired"
    },
    {
        "gamename": "Bomber Crew",
        "store": "Steam",
        "gamedescription": "Prepare your crew for their most dangerous mission yet and go chocks away for a sky-bound expedition in this strategic survival sim, Bomber Crew.",
        "gamelink": "https://store.steampowered.com/app/537800/Bomber_Crew/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/537800/header.jpg?t=1656003584",
        "freeuntil": "expired"
    },
    {
        "gamename": "Borderlands 3",
        "store": "Epic Games Store",
        "gamedescription": "The original shooter-looter returns, packing bazillions of guns and a mayhem-fueled adventure! Blast through new worlds & enemies and save your home from the most ruthless cult leaders in the galaxy.",
        "gamelink": "https://store.epicgames.com/p/borderlands-3",
        "gamecoverurl": "https://cdn1.epicgames.com/catnip/offer/BL3Wide_2560x1440-2560x1440-462318f36bee20de4412e111ef72dc62.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Prey",
        "store": "Epic Games Store",
        "gamedescription": "In Prey, you awaken aboard Talos I, a space station orbiting the moon in the year 2032. You are the key subject of an experiment meant to alter humanity forever – but things have gone terribly wrong.",
        "gamelink": "https://store.epicgames.com/p/prey",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_Prey_ArkaneStudios_S1_2560x1440-66103490180b56a8a132ae2487e18f2f?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Redout: Enhanced Edition",
        "store": "Epic Games Store",
        "gamedescription": "Redout is a tribute to the classic racing monsters. It is designed to be an uncompromising, fast, tough and satisfying driving experience, soaked in that vertigo that stands at the core of the arcade racing genre.",
        "gamelink": "https://store.epicgames.com/p/redout-enhanced-edition",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/93ea0d593cb04e62afb0741bbf894173/EGS_RedoutEnhancedEdition_34BigThingssrl_S1_2560x1440-23ab955c6537efdd53e1f407609f9036?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Jotun: Valhalla Edition",
        "store": "Epic Games Store",
        "gamedescription": "Jotun is a hand-drawn action-exploration game set in Norse mythology. In Jotun, you play Thora, a Viking warrior who died an inglorious death and must prove herself to the Gods to enter Valhalla. Impress the Gods!",
        "gamelink": "https://store.epicgames.com/p/jotun",
        "gamecoverurl": "https://cdn1.epicgames.com/undefined/offer/EGS_ThunderLotus_JotunValhallaEdition_S1-2560x1440-3de6d0151b1c97a08a8579167814682c.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Terraforming Mars",
        "store": "Epic Games Store",
        "gamedescription": "In Terraforming Mars, you control a corporation with a certain profile. Play project cards, build up production, place your cities and green areas on the map, and race for milestones and awards!",
        "gamelink": "https://store.epicgames.com/p/terraforming-mars-18c3ad",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/5199b206e46947ebad5e5c282e95776f/terraforming-mars-gdev2.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Mido and Di",
        "store": "Steam",
        "gamedescription": "Mido is an alien kid having nightmares about zombie aliens. One night he draws a creature, Di, and it comes alive to teach Mido how to fight zombies.",
        "gamelink": "https://store.steampowered.com/app/1691560/Mido_and_Di/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/1691560/header.jpg?t=1656870999",
        "freeuntil": "expired"
    },
    {
        "gamename": "Paradigm",
        "store": "Epic Games Store",
        "gamedescription": "Paradigm is a surreal adventure game set in the strange and post apocalyptic Eastern European country of Krusz. Play as the handsome mutant, Paradigm, whose past comes back to haunt him in the form of a genetically engineered sloth that vomits candy.",
        "gamelink": "https://store.epicgames.com/p/paradigm-875c5c",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/8e9d84a3fd2b4a3cbe4ac6b89ce9789d/paradigm-offer-1wcfe.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Just Die Already",
        "store": "Epic Games Store",
        "gamedescription": "Just Die Already is an old people mayhem sandbox game created by the designers of Goat Simulator. You are old and angry and you've just been kicked out of your retirement home. How will you survive in a world that wants you to Just Die Already?",
        "gamelink": "https://store.epicgames.com/p/just-die-already",
        "gamecoverurl": "https://cdn1.epicgames.com/salesEvent/salesEvent/EGS_JustDieAlready_DoubleMoose_S5_1920x1080-ab0a72182a2dbbead850ff3ab81a4f73?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Amnesia: Rebirth",
        "store": "Epic Games Store",
        "gamedescription": "Amnesia: Rebirth, a new descent into darkness from the creators of the iconic Amnesia series. A harrowing journey through desolation and despair, exploring the limits of human resilience.",
        "gamelink": "https://store.epicgames.com/p/amnesia-rebirth",
        "gamecoverurl": "https://cdn1.epicgames.com/b2df9b20c72446d690718965da41c7ad/offer/EGS_AmnesiaRebirth_FrictionalGames_S1-2560x1440-7d6c96d8e0ab6bfd7347f6730a7260a1.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Riverbond",
        "store": "Epic Games Store",
        "gamedescription": "Riverbond is a fun and frantic couch co-op adventure game for 1 to 4 players set in a stunning voxel world. Embark on a heroic journey to complete missions, battle adorable enemies, and smash everything into tiny cubes!",
        "gamelink": "https://store.epicgames.com/p/riverbond-782aa4",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/9d351e3efed04d1daa1fd6d7f8934f68/riverbond-offer-16k4h.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Postal 2",
        "store": "GoG",
        "gamedescription": "The one that re-started it all! The Dude shows up for his first day working at RWS and hilarity ensues! Fight cops! Rage against the Man! Snuff the Taliban! Buy Milk! Get an autograph from Gary Coleman! Forget what you know about first person shooters. Walk for a whole week in the Postal Dude's shoes.",
        "gamelink": "https://www.gog.com/game/postal_2",
        "gamecoverurl": "https://images.gog-statics.com/d852a13d79875678614aee8b5f534cd391eafcc51d443053d42b8210f1cb1d2a_product_tile_256.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Iris and the Giant",
        "store": "GoG",
        "gamedescription": "Iris and the Giant is a fusion of the roguelike, RPG and CCG genres. You play as Iris, and will discover her psyche and anxieties.",
        "gamelink": "https://www.gog.com/en/game/iris_and_the_giant_deckbuilding_roguelike",
        "gamecoverurl": "https://img.youtube.com/vi/1n8rAOEorB4/hqdefault.jpg",
        "freeuntil": "expired"
    },
    {
        "gamename": "Insurmountable",
        "store": "Epic Games Store",
        "gamedescription": "Confront various terrains as a lonely mountaineer and endure the countless dangers of climbing! Many tough decisions await you in a risky environment. Make your way up and down the mountain, overcoming insurmountable heights!",
        "gamelink": "https://store.epicgames.com/p/insurmountable-b02c31",
        "gamecoverurl": "https://cdn1.epicgames.com/spt-assets/dbc7343b22614dab86a493df7ba9803d/insurmountable-offer-1fpj8.jpg?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "XCOM 2",
        "store": "Epic Games Store",
        "gamedescription": "Aliens rule Earth, but secretly hiding a sinister agenda. As the leader of a guerrilla force, you must ignite a global resistance to eliminate the alien threat and save the human race.",
        "gamelink": "https://store.epicgames.com/p/xcom-2",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/7ec453d446194b8f8afe82aaa9561211/XCOM2_Set_Up_Assets_2560x1440_2560x1440-24cabf9f3c4eedd602a340a4658b2278?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    },
    {
        "gamename": "Minion Masters - Frost Dragon's Lai (DLC)",
        "store": "Steam",
        "gamedescription": "Frost Dragon’s Lair DLC is a great +300% value bundle that gives you what you need to build your own Stoutheart deck, including the legendary Scott the Sensitive Savage! It also gives you a headstart in this month’s Season Pass.",
        "gamelink": "https://store.steampowered.com/app/1954710/Minion_Masters__Frost_Dragons_Lair/",
        "gamecoverurl": "https://cdn.akamai.steamstatic.com/steam/apps/1954710/header.jpg?t=1649439425",
        "freeuntil": "expired"
    },
    {
        "gamename": "The Vanishing of Ethan Carter",
        "store": "Epic Games Store",
        "gamedescription": "The Vanishing of Ethan Carter is a first-person mystery focused on exploration and discovery. As an occult-minded detective Paul Prospero, piece together the truth behind Ethan’s disappearance — and the fate of his family.",
        "gamelink": "https://store.epicgames.com/p/the-vanishing-of-ethan-carter",
        "gamecoverurl": "https://cdn1.epicgames.com/offer/f401d7e187e349138380d22e991b29ca/EGS_TheVanishingofEthanCarter_TheAstronauts_S1_2560x1440-c0166f0ef48b7f939393e5cedc973ac2?h=270&quality=medium&resize=1&w=480",
        "freeuntil": "expired"
    }
]

export default json
